import { useState, useEffect } from 'react';
import { Grid, GridCellProps, GridColumn, GridDataStateChangeEvent, GridFilterChangeEvent, GridSortChangeEvent } from '@progress/kendo-react-grid';
import '@progress/kendo-theme-default/dist/all.css';
import { gridSettings, initialSort } from "configs/gridSettings";
import useOtherObjects from 'context/otherObjectsContext';
import { ActionColumns } from 'components/grid/action-column';
import { LabelCell } from 'components/grid/label-cell';
import { FullPageLoader } from 'components/shared/fullPageLoader';
import { initialDataState } from 'configs/initialDataState';
import { GuidCell } from 'components/grid/GuidCell';
import { CompositeFilterDescriptor, SortDescriptor } from '@progress/kendo-data-query';
import Toast from 'components/toaster/toastr';
import ErrorToast from 'components/toaster/errorToastr';
import SWDialog from 'components/shared/dialog';
import { RangeFilterCell } from 'components/grid/DateRangeFilterCell';
import { NavLink, useSearchParams } from 'react-router-dom';
import { useSearchPagination } from 'components/hooks/useSearchPagination';

export const OtherObjectsTable = () => {
  const [dataState, setDataState] = useState(initialDataState);
  const { otherObjects, meta, getOtherObjects, isSuccess, message, error, removeOtherObjectById } = useOtherObjects();
  const [filter, setFilter] = useState<CompositeFilterDescriptor | any>(undefined);
  const [deleteId, setDeleteId] = useState<string>("");
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [sort, setSort] = useState(initialSort);
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchPageNumber, searchPageSize] = useSearchPagination();

  useEffect(() => {
    if (dataState.isInitial) return;
    const page = dataState.skip / dataState.take + 1;
    const take = String(dataState.take);
    setSearchParams(`?${new URLSearchParams({ pageNumber: String(page), pageSize: take })}`);
    getOtherObjects({
      page: page, take: dataState.take, filters: filter?.filters, sort: sort
    })
  }, [dataState])

  useEffect(() => {
    const d = { ...dataState }
    d.take = searchPageSize;
    d.skip = (searchPageNumber - 1) * searchPageSize;
    d.isInitial = false;
    setDataState(d);
  }, []);

  const filterChange = (event: GridFilterChangeEvent) => {
    setFilter(event.filter);
  }

  const sortChanged = (sort: SortDescriptor[]) => {
    const page = dataState.skip / dataState.take + 1;
    getOtherObjects({ page: page, take: dataState.take, filters: filter?.filters, sort: sort })
  }

  useEffect(() => {
    if (filter === undefined) return;
    const getData = setTimeout(() => {
      const page = dataState.skip / dataState.take + 1;
      getOtherObjects({ page: page, take: dataState.take, filters: filter?.filters, sort: sort });
    }, 500);

    return () => clearTimeout(getData);
  }, [filter]);

  const removeOtherObject = () => {
    setIsDialogOpen(false);
    removeOtherObjectById(deleteId);
  }

  return (<div>
    <SWDialog
      open={isDialogOpen}
      confirmText="Delete"
      text="Are you sure that you want to delete this Other Object?"
      onConfirm={() => removeOtherObject()}
      onClose={() => setIsDialogOpen(false)}
    />
    {isSuccess && <Toast message={message} />}
    {error && <ErrorToast message={error} />}
    {
      otherObjects && meta ? <Grid
        {...gridSettings}
        data={otherObjects}
        filter={filter}
        onFilterChange={filterChange}
        total={meta.total_count}
        pageSize={dataState.take}
        className="k-grid-container"
        onDataStateChange={(e: GridDataStateChangeEvent) => {
          setDataState(e.dataState as any);
        }}
        skip={meta.page_size * meta.current_page - meta.page_size}
        sort={sort}
        onSortChange={(e: GridSortChangeEvent) => {
          setSort(e.sort);
          sortChanged(e.sort);
        }}
      >
        <GridColumn field="id" title="UUID" cell={GuidCell} width="110" />
        <GridColumn field="name" title="Name" width="300px" cell={(o: GridCellProps) => (
          <td>
            <NavLink to={`/other-objects/${o.dataItem.id}`} replace={true}>
              {o.dataItem.name}
            </NavLink>
          </td>
        )} />
        <GridColumn field="labels" title="Labels" cell={LabelCell} />
        <GridColumn field="created_at" width="240" filterCell={RangeFilterCell} title="Created at" />
        <GridColumn field="updated_at" width="240" filterCell={RangeFilterCell} title="Updated at" />
        <GridColumn field="actions" sortable={false} title="Actions" filterable={false} cell={o => (
          <ActionColumns id={o.dataItem.id} route="other-objects" onDelete={false} />
        )} />
      </Grid > :
        <FullPageLoader />
    }
  </div >);
};
