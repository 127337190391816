import { useForm } from "react-hook-form";
import useModelSubscriptions from 'context/modelSubscriptionsContext';
import { SaveButton } from "components/shared/saveButton";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Controller } from "react-hook-form";
import { Checkbox, FormControlLabel } from "@mui/material";

const EditModelSubscription = (props: any) => {
  const { editModelSubscription } = useModelSubscriptions();
  let { catalogId, editModelSubscriptionId } = useParams();
  let location = useLocation();
  const modelSubscription = location.state.modelSubscription;

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      active: modelSubscription.active,
    }
  });

  return (
    <form
      className={errors.active ? "was-validated" : "needs-validation"}
      onSubmit={handleSubmit((data: any) => {
        editModelSubscription(data, catalogId, editModelSubscriptionId).then((error: any) => {
          if (!error) {
            props.onExit(); // Wyjście po zapisaniu
          }
        });
      })}
      noValidate
    >
      <div className="card my-3">
        <div className="card-body">
          {/* Nazwa modelu subskrypcji */}
          <label className="form-label">Model Subscription</label>
          <p className="mb-0">{modelSubscription.model_list?.name || "No name available"}</p>
        </div>
      </div>

      <div className="card my-3">
        <div className="card-body">
          <FormControlLabel
            control={
              <Controller
                name={"active"}
                control={control}
                render={({ field: props }) => (
                  <Checkbox
                    {...props}
                    checked={!!props.value}
                    onChange={(e) => props.onChange(e.target.checked)}
                  />
                )}
              />
            }
            label={<>
              <label>Active <br />
                <span className="small small-bottom">Enable subscription proces from model list</span>
              </label>
            </>}
          />
        </div>
      </div>

      <div className="bottom-bar">
        <SaveButton />
      </div>

    </form>
  );
}

export default EditModelSubscription;
