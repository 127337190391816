const NiceProgress = ({ basePhrase }: { basePhrase: string }) => {
  const phrases = [
    "Did you know? You're awesome!",
    "Good things are coming your way!",
    "A little patience leads to great results!",
    "Smiles are contagious; pass one on!",
    "Believe in yourself; you're doing great!",
    "Every moment is a fresh beginning.",
    "Thank you for your patience!",
    "Your positivity brightens our day!",
    "Great things take time!",
    "Keep shining bright!",
    "You're making a difference!",
    "Wishing you a fantastic day!",
    "Hang tight, amazing things ahead!",
    "You're in good company!",
    "We appreciate you being here!",
  ];

  const getRandomPhrase = () => {
    const randomIndex = Math.floor(Math.random() * phrases.length);
    return phrases[randomIndex];
  };

  const randomPhrase = getRandomPhrase();

  return (
    <div>
      <p style={{ fontWeight: 'bold' }}>{basePhrase} <span style={{ color: '#ff5733' }}>{randomPhrase}</span></p>
    </div>
  );
};

export default NiceProgress;