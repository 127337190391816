import { Drawer } from "@mui/material";
import axios from "axios";
import CommonLayout from "components/layouts/common-layout";
import { CustomAttributesTable } from "components/customAttributes/customAttributesTable";
import { CustomAttributesProvider } from "context/customAttributesContext";
import { FormMode } from "models/util";
import { ProductCatalog } from "models/product_catalog";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddIcon from '@mui/icons-material/Add';
import EditCustomAttribute from "modules/productCatalogs/customAttributes/editCustomAttribute";
import CreateCustomAttribute from "modules/productCatalogs/customAttributes/createCustomAttribute";

interface CustomAttributeProps {
  mode: FormMode,
  catalog?: ProductCatalog
}

const CustomAttributesPage = (props: CustomAttributeProps) => {
  const [drawerTitle, setDrawerTitle] = useState<string>("Custom Attribute")
  let { editId, catalogId } = useParams();
  const [catalog, setCatalog] = useState<any>({});
  const [customAttribute, setCustomAttribute] = useState<any>({});
  const [mode, setMode] = useState<FormMode>(props.mode)
  const navigate = useNavigate();

  useEffect(() => {
    if (catalogId && editId) {
      setCustomAttribute(null);
      setMode(FormMode.Edit);
      getCustomAttributeById(catalogId, editId).then((res: any) => {
        setCustomAttribute(res.data.data)
      })
    }
  }, [catalogId, editId]);

  useEffect(() => {
    if (catalogId) {
      setCatalog(null);
      getProductCatalogById(catalogId).then((res: any) => {
        setCatalog(res.data.data)
      })
    }
  }, [catalogId]);

  useEffect(() => {
    if (mode === FormMode.Create) {
      setDrawerTitle("Add Custom Attribute")
    } else if (mode === FormMode.Edit) {
      setDrawerTitle("Edit Custom Attribute")
    }
  }, [mode]);

  const getProductCatalogById = (id: string) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/product_catalogs/${id}`)
  }

  const getCustomAttributeById = (catalogId: string, attributeId: string) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/product_catalogs/${catalogId}/attribute_values/${attributeId}`)
  }

  const exitToCatalog = () => {
    setMode(FormMode.None);
    navigate("/customer-product-lists/products/" + catalogId, { replace: true })
  }

  const onExit = () => {
    setMode(FormMode.None);
    navigate("/customer-product-lists/custom-attributes/" + catalogId, { replace: true })
  }

  return (
    <CommonLayout>
      <h2 className="float-start">{catalogId &&
        <ArrowBackIcon onClick={exitToCatalog} className="primary-col pointer" />}
        {catalog && catalogId ?
          <>
            <span className="mx-3">
              <span className="light-gray small">Custom Attributes</span>
              <span className="larger">
                {catalog?.name}
              </span>
            </span>
          </>
          :
          <>
            Custom Attributes
          </>
        }</h2>
      <button onClick={() => {
        setMode(FormMode.Create)
        navigate(`/customer-product-lists/custom-attributes/${catalogId}/new`, { replace: true })
      }} className="btn btn-primary btn-block fw-bolder float-end">
        <AddIcon />Add Custom Attribute
      </button>

      <CustomAttributesProvider>
        <div className="clearfix"></div>
        <CustomAttributesTable />
        <Drawer
          PaperProps={{ style: { width: '600px' } }}
          anchor={'right'}
          open={mode !== FormMode.None}
          onClose={onExit}
        >
          <section className="sidebar-entity p-4">
            <h2 className="float-start">
              <ArrowBackIcon onClick={onExit} className="primary-col pointer" /><span className="px-2">{drawerTitle}</span>
            </h2>
            <div className="clearfix" />
            {mode === FormMode.Edit && customAttribute && <EditCustomAttribute onExit={onExit} customAttribute={customAttribute} />}
            {mode === FormMode.Create && <CreateCustomAttribute onExit={onExit} customAttribute={customAttribute} />}
          </section>
        </Drawer>
      </CustomAttributesProvider>
    </CommonLayout>

  )
}

export default CustomAttributesPage;
