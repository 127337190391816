import { ModelSubscription } from "models/model_subscription";
import { Meta } from "models/util";
import { DEFAULT_ERROR } from "models/util"; // Dodaj, jeśli masz domyślny błąd

export interface ModelSubscriptionsState {
  isLoading: boolean;
  modelSubscriptions: ModelSubscription[];
  meta?: Meta;
  metaModelSubscriptions: Meta;
  error?: string;
  isSuccess?: boolean;
  message?: string;
  attrErrors?: string[];
  getModelSubscriptions?: any;
  createModelSubscription?: any;
  editModelSubscription?: any;
  removeModelSubscriptionById?: any;
}

export const initialState: ModelSubscriptionsState = {
  isLoading: false,
  modelSubscriptions: [],
  meta: { current_page: 1, page_size: 20, total_count: 20 },
  metaModelSubscriptions: { current_page: 1, page_size: 20, total_count: 20 },
  error: undefined,
  isSuccess: false,
  attrErrors: [],
};

const addAttrErrors = (state: ModelSubscriptionsState, newAttrErrors: string[]) => {
  if (state.attrErrors && state.attrErrors.length > 0) {
    return [...state.attrErrors, ...newAttrErrors];
  }
  return newAttrErrors;
}

type Action =
  | { type: 'request' }
  | { type: 'success', results: { modelSubscriptions: ModelSubscription[], meta?: Meta, metaModelSubscriptions: Meta } }
  | { type: 'failure', error?: string }
  | { type: 'actionSuccess', message: string }
  | { type: 'clearActionSuccess' }
  | { type: 'clearActionFailure' }
  | { type: 'attributeErrors', attrErrors: string[] }
  | { type: 'resetModelSubscriptions' };

export function modelSubscriptionsReducer(state: ModelSubscriptionsState, action: Action): ModelSubscriptionsState {
  switch (action.type) {
    case 'request':
      return { ...state, modelSubscriptions: [], isLoading: true, error: undefined };
    case 'success':
      return { ...state, isLoading: false, modelSubscriptions: action.results.modelSubscriptions, meta: action.results.meta, metaModelSubscriptions: action.results.metaModelSubscriptions };
    case 'failure':
      return { ...state, isLoading: false, error: action.error || DEFAULT_ERROR, isSuccess: false };
    case 'actionSuccess':
      return { ...state, isSuccess: true, message: action.message, error: undefined };
    case 'clearActionSuccess':
      return { ...state, isSuccess: false, message: undefined };
    case 'clearActionFailure':
      return { ...state, error: undefined };
    case 'attributeErrors':
      return { ...state, attrErrors: addAttrErrors(state, action.attrErrors) };
    case 'resetModelSubscriptions':
      return { ...state, modelSubscriptions: [], error: undefined }
    default:
      return state;
  }
}
