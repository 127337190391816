import axios from "axios";
import { createContext, useReducer, useContext, ReactNode } from "react";
import { importReducer, initialState } from "reducers/importsReducer";
import { convertFiltersToQueryString, Meta, generateSortQueryParam, GetEntitiesProps } from "models/util";
import { processDateObjects } from "shared/functions/processDateObjects";
import { useNavigate } from "react-router-dom";
import { Import } from "models/import";

const ImportsContext = createContext(initialState);

interface Props {
  children?: ReactNode
}

export const ImportsProvider = ({ children }: Props) => {
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(importReducer, initialState);

  type TypeMappingKeys = "Customer Product Lists" | "Predefined Class Labels" | "Predefined Class Products" | "Labels Tags" | "Products" | "Model List Products" | "Customer References" | "Remove Labels from PCs";

  const getImports = ({ page = 1, take = 20, filters = [], sort = [] }: GetEntitiesProps) => {
    const typeMapping: Record<TypeMappingKeys, string> = {
      "Customer Product Lists": "product_catalogs",
      "Predefined Class Labels": "predefined_class_labels",
      "Predefined Class Products": "predefined_class_products",
      "Labels Tags": "labels_tags",
      "Products": "products",
      "Model List Products":  "model_list_products",
      "Customer References": "customer_references",
      "Remove Labels from PCs": "remove_labels_from_predefined_classes",
    };

    const getApiValue = (displayedValue: TypeMappingKeys) => typeMapping[displayedValue] || displayedValue;
    const mappedFilters = filters.map(filter => {
      if (filter.field === 'import_type') {
        return { ...filter, value: getApiValue(filter.value) };
      }
      return filter;
    });
    const f = convertFiltersToQueryString(mappedFilters);
    setLoading();
    axios.get(`${process.env.REACT_APP_API_URL}/imports/?page=${page}&items_per_page=${take}${f}${generateSortQueryParam(sort)}`)
      .then(res => {
          setImports(processDateObjects(res.data.data) as Import[], res.data.meta);
          },
          () => setFailure("Error getting imports"));
  }


  const clearActionSuccess = () => {
    dispatch({ type: "clearActionSuccess" })
  }
  const clearActionFailure = () => {
    dispatch({ type: "clearActionFailure" })
  }

  const setImports = (imports: Import[], meta: Meta) => {
    dispatch({
      type: "success",
      results: { imports: imports, meta },
    });
  };

  const setLoading = () => {
    dispatch({
      type: "request",
    });
  }

  const setFailure = (errorMessage: string) => {
    dispatch({
      type: "failure",
      error: errorMessage
    });
  }

  const value = {
    isLoading: state.isLoading,
    error: state.error,
    imports: state.imports,
    meta: state.meta,
    setImports: setImports,
    getImports: getImports,
    setLoading,
    setFailure,
    isSuccess: state.isSuccess,
    message: state.message,
  };
  return <ImportsContext.Provider value={value}>{children}</ImportsContext.Provider>;
};

const useImports = () => {
  const context = useContext(ImportsContext);

  if (context === undefined) {
    throw new Error("useImports must be used within ImportsContext");
  }

  return context;
};

export default useImports;
