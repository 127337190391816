import logo from './bbsmall.svg';
import Profile from 'modules/auth0/Profile';
import { NavLink } from 'react-router-dom';
import { featureFlags } from 'configs/featureFlags';
import usePermissions from 'context/permissionsContext';
import { PermissionGate, hasPermission } from 'shared/functions/permissionGate';

export default function MainMenu() {
  const { permissions } = usePermissions();

  const isActive = (path: string) => {
    const activeItem = window.location.pathname;
    return activeItem === path ? 'active' : '';
  };

  const isResourcesDropdownActive = () => {
    const activeItem = window.location.pathname;
    return [
      '/categories',
      '/brands',
      '/brand-categories',
      '/brand-families',
      '/suppliers',
      '/classifications',
      '/subclassifications',
      '/other-objects',
      '/labels',
      '/predefined-classes',
      '/model-lists',
    ].includes(activeItem)
      ? 'active'
      : '';
  };

  const resourceItems: JSX.Element[] = [];

  if (hasPermission('read', 'categories', permissions)) {
    resourceItems.push(
      <li className={isActive('/categories')} key="/categories">
        <NavLink to="/categories" className="dropdown-item">
          Categories
        </NavLink>
      </li>
    );
  }

  if (hasPermission('read', 'brands', permissions)) {
    resourceItems.push(
      <li className={isActive('/brands')} key="/brands">
        <NavLink to="/brands" className="dropdown-item">
          Brands
        </NavLink>
      </li>
    );
  }

  if (hasPermission('read', 'brand_categories', permissions)) {
    resourceItems.push(
      <li className={isActive('/brand-categories')} key="/brand-categories">
        <NavLink to="/brand-categories" className="dropdown-item">
          Brand Categories
        </NavLink>
      </li>
    );
  }

  if (hasPermission('read', 'brand_families', permissions)) {
    resourceItems.push(
      <li className={isActive('/brand-families')} key="/brand-families">
        <NavLink to="/brand-families" className="dropdown-item">
          Brand Families
        </NavLink>
      </li>
    );
  }

  if (hasPermission('read', 'suppliers', permissions)) {
    resourceItems.push(
      <li className={isActive('/suppliers')} key="/suppliers">
        <NavLink to="/suppliers" className="dropdown-item">
          Suppliers
        </NavLink>
      </li>
    );
  }

  if (hasPermission('read', 'classifications', permissions)) {
    resourceItems.push(
      <li className={isActive('/classifications')} key="/classifications">
        <NavLink to="/classifications" className="dropdown-item">
          Classifications
        </NavLink>
      </li>
    );
  }

  if (hasPermission('read', 'subclassifications', permissions)) {
    resourceItems.push(
      <li className={isActive('/subclassifications')} key="/subclassifications">
        <NavLink to="/subclassifications" className="dropdown-item">
          Subclassifications
        </NavLink>
      </li>
    );
  }

  if (hasPermission('read', 'other_objects', permissions)) {
    resourceItems.push(
      <li className={isActive('/other-objects')} key="/other-objects">
        <NavLink to="/other-objects" className="dropdown-item">
          Other Objects
        </NavLink>
      </li>
    );
  }

  if (hasPermission('read', 'labels', permissions)) {
    resourceItems.push(
      <li className={isActive('/labels')} key="/labels">
        <NavLink to="/labels" className="dropdown-item">
          Labels
        </NavLink>
      </li>
    );
  }

  if (featureFlags.modelLists && hasPermission('read', 'model_lists', permissions)) {
    resourceItems.push(
      <li className={isActive('/model-lists')} key="/model-lists">
        <NavLink to="/model-lists" className="dropdown-item">
          Model Lists
        </NavLink>
      </li>
    );
  }

  return (
    <header>
      <nav className="px-3 py-2 navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid">
          <div className="d-flex flex-wrap">
            <a
              href="/products"
              className="d-flex align-items-center my-2 my-lg-0 me-lg-auto text-decoration-none"
            >
              <img src={logo} alt="BrandBank" />
            </a>
            <ul className="nav col-12 col-lg-auto my-2 justify-content-center my-md-0 text-small">
              <PermissionGate permission="read" resource="products">
                <li className={isActive('/products')}>
                  <NavLink to="/products" className="nav-link">
                    Products
                  </NavLink>
                </li>
              </PermissionGate>
              <PermissionGate permission="read" resource="product_catalogs">
                <li className={isActive('/customer-product-lists/products')}>
                  <NavLink to="/customer-product-lists/products" className="nav-link">
                    Customer Product Lists
                  </NavLink>
                </li>
              </PermissionGate>
              <PermissionGate permission="read" resource="products">
                <li className={isActive('/product-packshots')}>
                  <NavLink to="/product-packshots" className="nav-link">
                    Packshots
                  </NavLink>
                </li>
              </PermissionGate>
              <PermissionGate permission="read" resource="predefined_classes">
                <li className={isActive('/predefined-classes')}>
                  <NavLink to="/predefined-classes" className="nav-link">
                    Predefined Classes
                  </NavLink>
                </li>
              </PermissionGate>

              {resourceItems.length > 0 && (
                <li className={`nav-item dropdown ${isResourcesDropdownActive()}`}>
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="resourcesDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Resources
                  </a>
                  <ul className="dropdown-menu" aria-labelledby="resourcesDropdown">
                    {resourceItems}
                  </ul>
                </li>
              )}

              <PermissionGate permission="read" resource="imports">
                <li className={isActive('/imports')}>
                  <NavLink to="/imports" className="nav-link">
                    Imports
                  </NavLink>
                </li>
              </PermissionGate>
              {featureFlags.packshotsIngestion && (
                <PermissionGate permission="write" resource="products">
                  <li className={isActive('/packshots-ingestion')}>
                    <NavLink to="/packshots-ingestion" className="nav-link">
                      Packshots Ingestion
                    </NavLink>
                  </li>
                </PermissionGate>
              )}
              {featureFlags.exports && (
                <PermissionGate permission="read" resource="exports">
                  <li className={isActive('/exports')}>
                    <NavLink to="/exports" className="nav-link">
                      Exports
                    </NavLink>
                  </li>
                </PermissionGate>
              )}
            </ul>
          </div>
          <div className="d-flex">
            <Profile />
          </div>
        </div>
      </nav>
    </header>
  );
}
