// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-container {
  position: relative;
  overflow-y: scroll;
}

.gallery-image {
  width: 100%;
  height: 150px;
  object-fit: cover;
  cursor: pointer;
  border: 1px solid rgba(221, 221, 221, 0.5);
  transition: transform 0.2s;
}

.gallery-image:hover {
  transform: scale(1.05);
  z-index: 1;
}`, "",{"version":3,"sources":["webpack://./src/components/productPackshotsIngestion/image-gallery.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,iBAAiB;EACjB,eAAe;EACf,0CAA0C;EAC1C,0BAA0B;AAC5B;;AAEA;EACE,sBAAsB;EACtB,UAAU;AACZ","sourcesContent":[".image-container {\n  position: relative;\n  overflow-y: scroll;\n}\n\n.gallery-image {\n  width: 100%;\n  height: 150px;\n  object-fit: cover;\n  cursor: pointer;\n  border: 1px solid rgba(221, 221, 221, 0.5);\n  transition: transform 0.2s;\n}\n\n.gallery-image:hover {\n  transform: scale(1.05);\n  z-index: 1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
