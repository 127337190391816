import React from 'react';
import { Checkbox } from '@mui/material';
import { Label } from 'models/label';
import NoPhotographyIcon from '@mui/icons-material/NoPhotography';

interface SingleLabelProps {
  label: Label;
  onLabelChange: (l: { id: string; checked: boolean }) => void;
  isSelected: boolean;
  onLabelClick: (label: Label) => void;
}

const SingleLabel = (props: SingleLabelProps) => {
  const onChange = (checked: boolean) => {
    props.onLabelChange({ id: props.label.id, checked });
  };

  const onLabelContainerClick = () => {
    props.onLabelClick(props.label);
  };

  return (
    <div className="single-label-container row" onClick={onLabelContainerClick}>
      <div
        className="single-label-check col-1"
        onClick={(e) => e.stopPropagation()}
      >
        <Checkbox
          onChange={(e) => onChange(e.target.checked)}
          checked={props.isSelected}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      </div>
      <div className="single-label-photo col-1">
        {props.label.photos?.length ? (
          <img
            src={props.label.photos[0].url}
            className="list-thumbnail"
            alt="Label"
            style={{ width: '50px' }}
          />
        ) : (
          <div className="single-label-no-photo">
            <NoPhotographyIcon />
          </div>
        )}
      </div>
      <div className="single-label-name col-10 py-2">
        <a className="no-underline">{props.label.name}</a>
      </div>
    </div>
  );
};

export default SingleLabel;
