import CommonLayout from "components/layouts/common-layout"
import { useParams, NavLink } from 'react-router-dom'
import usePredefinedClasses from 'context/predefinedClassesContext';
import Toast from 'components/toaster/toastr';
import ErrorToast from 'components/toaster/errorToastr';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { PredefinedClass } from "models/predefined_class";
import usePredefinedClassLabels from "context/predefinedClassLabelsContext";
import { Grid, GridColumn, GridDataStateChangeEvent, GridFilterChangeEvent, GridSortChangeEvent } from '@progress/kendo-react-grid';
import { gridSettings, initialSort } from "configs/gridSettings";
import { RangeFilterCell } from 'components/grid/DateRangeFilterCell';
import { useSearchPagination } from 'components/hooks/useSearchPagination';
import { FullPageLoader } from 'components/shared/fullPageLoader';
import { initialDataState } from 'configs/initialDataState';
import { CompositeFilterDescriptor, SortDescriptor } from '@progress/kendo-data-query';
import { GuidCell } from 'components/grid/GuidCell';
import NoPhotographyIcon from '@mui/icons-material/NoPhotography';
import { DropdownFilterCell } from 'components/shared/dropdownFilterCell'
import { GridFilterCellProps } from '@progress/kendo-react-grid/dist/npm/interfaces/GridFilterCellProps';

const filterableEntityTypes = ["product", "brand", "brand category", "category", "other object"];
const EntityTypeFilterCell: any = (props: GridFilterCellProps) => <DropdownFilterCell {...props} data={filterableEntityTypes} defaultItem={'-'} />;

const PredefinedClassLabelsPage = (props: any) => {
  const { previewId } = useParams();
  const { isSuccess, error, message, predefinedClasses, getPredefinedClass } = usePredefinedClasses();
  const { error: labelsError, meta, getPredefinedClassLabels, labels } = usePredefinedClassLabels();
  const navigate = useNavigate();
  const [predefinedClass, setPredefinedClass] = useState({} as PredefinedClass);
  const [filter, setFilter] = useState<CompositeFilterDescriptor | any>(undefined);
  const [sort, setSort] = useState(initialSort);
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchPageNumber, searchPageSize] = useSearchPagination();
  const [labelsDataState, setlabelsDataState] = useState(initialDataState);

  useEffect(() => {
    getPredefinedClass(previewId)
    if (labels.length > 0) return;
    getPredefinedClassLabels({ predefinedClassId: previewId, sort: sort })

    const labelsData = { ...labelsDataState }
    labelsData.take = searchPageSize;
    labelsData.skip = (searchPageNumber - 1) * searchPageSize;
    labelsData.isInitial = false;
    setlabelsDataState(labelsData);
  }, []);

  useEffect(() => {
    if (predefinedClasses && predefinedClasses.length > 0) {
      setPredefinedClass(predefinedClasses[0]);
    }
  }, [predefinedClasses])

  useEffect(() => {
    if (labelsDataState.isInitial) return;
    const page = labelsDataState.skip / labelsDataState.take + 1;
    const take = String(labelsDataState.take);
    setSearchParams(`?${new URLSearchParams({ pageNumber: String(page), pageSize: take })}`);
    getPredefinedClassLabels({ page: page, take: labelsDataState.take, sort: sort, filters: (filter?.filters || []), predefinedClassId: previewId })
  }, [labelsDataState])

  useEffect(() => {
    if (filter === undefined) return;
    const getData = setTimeout(() => {
      getPredefinedClassLabels({ filters: filter?.filters, predefinedClassId: previewId })
    }, 600);

    return () => clearTimeout(getData);
  }, [filter]);

  const filterChange = (event: GridFilterChangeEvent) => {
    setFilter(event.filter);
  }

  const sortChanged = (sort: SortDescriptor[]) => {
    const page = labelsDataState.skip / labelsDataState.take + 1;
    getPredefinedClassLabels({ page: page, take: labelsDataState.take, filters: filter?.filters, sort: sort, predefinedClassId: previewId })
  }

  const fetchPhotoUrl = (dataItem: any) => {
    return dataItem.photos[0].url;
  }

  return (
    <CommonLayout>
      <h2 className="float-start">
        <ArrowBackIcon onClick={() => { navigate("/predefined-classes", { replace: true }) }} className="primary-col pointer" />
        <span className="px-2">{predefinedClass?.name}</span>
      </h2>
      {isSuccess && <Toast message={message} />}
      {error && <ErrorToast message={error} />}
      {labelsError && <ErrorToast message={labelsError} />}
      <NavLink to={`/predefined-classes/edit-labels/${previewId}`} replace={true}>
        <button type="button" className="save-ui btn btn-primary btn-block fw-bolder float-end">Assign labels</button>
      </NavLink>
      <div className="clearfix"></div>
      {labels && meta ? <Grid
        {...gridSettings}
        filter={filter}
        sortable={false}
        className="k-grid-container"
        onFilterChange={filterChange}
        data={labels}
        total={meta.total_count}
        pageSize={labelsDataState.take}
        onDataStateChange={(e: GridDataStateChangeEvent) => {
          setlabelsDataState(e.dataState as any);
        }}
        skip={meta.page_size * meta.current_page - meta.page_size}
        onSortChange={(e: GridSortChangeEvent) => {
          setSort(e.sort);
          sortChanged(e.sort);
        }}
      >
        <GridColumn sortable={false} field="photo" title="Packshot" width="150px" filterable={false} cell={o => (
          <td>
            {o.dataItem.photos.length > 0 ? <img src={fetchPhotoUrl(o.dataItem)} className="grid-thumbnail" />
              :
              <div style={{ "height": "36px", "width": "36px", "float": "left" }} >
                <NoPhotographyIcon></NoPhotographyIcon>
              </div>
            }
          </td>
        )} />
        <GridColumn field="id" title="Label UUID" sortable={false} cell={GuidCell} width="150" />
        <GridColumn field="name_like" title="Label Name" cell={o => (
          <td>
            <NavLink to={`/labels/${o.dataItem.id}`} replace={true}>
              {o.dataItem.name}
            </NavLink>
            <br />
          </td>
        )} />
        <GridColumn field="entity_type" width="230" title="Entity Type" filterCell={EntityTypeFilterCell} />
        <GridColumn field="entity_id" title="Entity UUID" sortable={false} filterable={false} width="150" />
        <GridColumn field="entity_name" title="Entity Name" cell={o => (
          <td>
            <NavLink to={`/products/${o.dataItem.entity_id}`} replace={true}>
              {o.dataItem.entity_name}
            </NavLink>
            <br />
          </td>
        )} />
        <GridColumn field="created_at" width="240" filterCell={RangeFilterCell} title="Created at" />
        <GridColumn field="updated_at" width="240" filterCell={RangeFilterCell} title="Updated at" />
      </Grid > :
        <FullPageLoader />
      }
    </CommonLayout>
  )
}

export default PredefinedClassLabelsPage;
