import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import useProduct from "context/productContext";
import {
  Autocomplete,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  TextField,
} from "@mui/material";
import useBrands from "context/brandsContext";
import useCategories from "context/categoriesContext";
import useBrandFamilies from "context/brandsFamiliesContext";
import useSuppliers from "context/suppliersContext";
import useSubClassifications from "context/subClassificationContext";
import useClassifications from "context/classificationsContext";
import LockIcon from "@mui/icons-material/Lock";
import { SaveButton } from "components/shared/saveButton";
import FileUpload from "./fileUpload";

const AddProduct = (props: any) => {
  const { createProduct } = useProduct();
  const { brandFamilies, getBrandFamilies } = useBrandFamilies();
  const { getBrands, brands } = useBrands();
  const { categories, getCategories } = useCategories();
  const [isValidated, setIsValidated] = useState<boolean>(false);
  const { getSuppliers, suppliers } = useSuppliers();
  const { subClassifications, getSubClassifications } = useSubClassifications();
  const { classifications, getClassifications } = useClassifications();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      name: "",
      ean: "",
      upc: "",
      brand: undefined,
      category: undefined,
      brand_family: undefined,
      classification: undefined,
      subclassification: undefined,
      supplier: undefined,
      size: "",
      pack_size: "",
      pack_type: "",
      region: "",
      create_label_automatically: true,
      kafka_sendable: true,
      kafka_excluded_from_search: false,
      photo_ids: [],
    },
  });

  const fullName = watch("name");
  const region = watch("region");
  const pack_type = watch("pack_type");
  const pack_size = watch("pack_size");
  const size = watch("size");
  const photo_ids = watch("photo_ids");

  // test BEGIN
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<readonly any[]>([]);
  const loading = open && options.length === 0;

  useEffect(() => {
    getBrands({ page: 1, items_per_page: 200 });
    getCategories({ page: 1, items_per_page: 200 });
    getBrandFamilies({ page: 1, items_per_page: 200 });
    getSuppliers({ page: 1, items_per_page: 200 });
    getSubClassifications({ page: 1, items_per_page: 200 });
    getClassifications({ page: 1, items_per_page: 200 });
  }, []);

  const getBrandForName = (searchTerm: string) => {
    getBrands({
      page: 1,
      items_per_page: 20,
      filters: [{ field: "name", value: searchTerm }],
    });
  };
  const getSupplierForName = (searchTerm: string) => {
    getSuppliers({
      page: 1,
      items_per_page: 20,
      filters: [{ field: "name", value: searchTerm }],
    });
  };
  const getCategoryForName = (searchTerm: string) => {
    getCategories({
      page: 1,
      items_per_page: 20,
      filters: [{ field: "name", value: searchTerm }],
    });
  };
  const getBrandFamilyForName = (searchTerm: string) => {
    getBrandFamilies({
      page: 1,
      items_per_page: 20,
      filters: [{ field: "name", value: searchTerm }],
    });
  };
  const getClassificationForName = (searchTerm: string) => {
    getClassifications({
      page: 1,
      items_per_page: 20,
      filters: [{ field: "name", value: searchTerm }],
    });
  };
  const getSubclassificationForName = (searchTerm: string) => {
    getSubClassifications({
      page: 1,
      items_per_page: 20,
      filters: [{ field: "name", value: searchTerm }],
    });
  };

  return (
    <div className="product-split row">
      <div className="product-form col-6">
        <form
          className={errors.name ? "was-validated" : "needs-validation"}
          onSubmit={handleSubmit((data) => {
            createProduct(data).then((error: any) => {
              if (!error) {
                props.onExit();
              }
            });
          })}
          noValidate
        >
          <div className="card">
            <div className="card-body">
              <label className="form-label">Name</label>
              <input
                className="form-control"
                {...register("name", { required: true })}
                required
              />
              {errors.name && (
                <div className="invalid-feedback">
                  <p className="warning-icon">This field is required.</p>
                </div>
              )}
              <div className="my-3 px-3">
                <label className="form-label">
                  Full Name &nbsp; <LockIcon fontSize="inherit" />
                </label>
                <input
                  className="form-control"
                  disabled
                  value={
                    fullName + " " + pack_size + " " + pack_type + "  " + size
                  }
                />
              </div>
              <div className="row">
                <div className="col-6">
                  <label className="form-label">
                    EAN<span className="small"> (optional)</span>
                  </label>
                  <input
                    className="form-control"
                    {...register("ean", { required: false })}
                  />
                </div>
                <div className="col-6">
                  <label className="form-label">
                    UPC<span className="small"> (optional)</span>
                  </label>
                  <input
                    className="form-control"
                    {...register("upc", { required: false })}
                  />
                </div>
              </div>
              <div className="my-3 px-3">
                <label className="form-label">
                  Region<span className="small"> (optional)</span>
                </label>
                <input
                  className="form-control"
                  {...register("region", { required: false })}
                />
              </div>
            </div>
          </div>
          <div className="card my-3">
            <div className="my-3 px-3">
              <Autocomplete
                onInputChange={(event, newInputValue) => {
                  getCategoryForName(newInputValue);
                }}
                onChange={(event: any, newValue: any | null) => {
                  setValue("category", newValue?.id);
                }}
                isOptionEqualToValue={(option, value) =>
                  option.name === value.name
                }
                getOptionLabel={(option) => option.name}
                options={categories}
                loading={loading}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!!errors.category}
                    variant="outlined"
                    helperText={!!errors.category && "This field is required."}
                    fullWidth
                    label="Category"
                    InputProps={{
                      ...register("category", {
                        required: "This field is required.",
                      }),
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </div>
          </div>
          <div className="card my-3">
            <div className="my-3 px-3">
              <Autocomplete
                onInputChange={(event, newInputValue) => {
                  getSupplierForName(newInputValue);
                }}
                onChange={(event: any, newValue: any | null) => {
                  setValue("supplier", newValue?.id);
                }}
                isOptionEqualToValue={(option, value) =>
                  option.name === value.name
                }
                getOptionLabel={(option) => option.name}
                options={suppliers}
                loading={loading}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!!errors.supplier}
                    variant="outlined"
                    helperText={!!errors.supplier && "This field is required."}
                    fullWidth
                    label={
                      <>
                        Supplier <span className="small"> (optional)</span>
                      </>
                    }
                    InputProps={{
                      ...register("supplier"),
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </div>
            <div className="my-3 px-3">
              <Autocomplete
                onInputChange={(event, newInputValue) => {
                  getBrandFamilyForName(newInputValue);
                }}
                onChange={(event: any, newValue: any | null) => {
                  setValue("brand_family", newValue?.id);
                }}
                isOptionEqualToValue={(option, value) =>
                  option.name === value.name
                }
                getOptionLabel={(option) => option.name}
                options={brandFamilies}
                loading={loading}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!!errors.brand_family}
                    variant="outlined"
                    helperText={
                      !!errors.brand_family && "This field is required."
                    }
                    fullWidth
                    label={
                      <>
                        Brand Family <span className="small"> (optional)</span>
                      </>
                    }
                    InputProps={{
                      ...register("brand_family"),
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </div>
            <div className="my-3 px-3">
              <Autocomplete
                onInputChange={(event, newInputValue) => {
                  getBrandForName(newInputValue);
                }}
                onChange={(event: any, newValue: any | null) => {
                  setValue("brand", newValue?.id);
                }}
                isOptionEqualToValue={(option, value) =>
                  option.name === value.name
                }
                getOptionLabel={(option) => option.name}
                options={brands}
                loading={loading}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!!errors.brand}
                    variant="outlined"
                    helperText={!!errors.brand && "This field is required."}
                    fullWidth
                    label="Brand"
                    InputProps={{
                      ...register("brand", {
                        required: "This field is required.",
                      }),
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </div>
          </div>
          <div className="card">
            <div className="my-3 px-3">
              <Autocomplete
                onInputChange={(event, newInputValue) => {
                  getClassificationForName(newInputValue);
                }}
                onChange={(event: any, newValue: any | null) => {
                  setValue("classification", newValue?.id);
                }}
                isOptionEqualToValue={(option, value) =>
                  option.name === value.name
                }
                getOptionLabel={(option) => option.name}
                options={classifications}
                loading={loading}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!!errors.classification}
                    variant="outlined"
                    helperText={
                      !!errors.classification && "This field is required."
                    }
                    fullWidth
                    label={
                      <>
                        Classification{" "}
                        <span className="small"> (optional)</span>
                      </>
                    }
                    InputProps={{
                      ...register("classification"),
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </div>
            <div className="my-3 px-3">
              <Autocomplete
                onInputChange={(event, newInputValue) => {
                  getSubclassificationForName(newInputValue);
                }}
                onChange={(event: any, newValue: any | null) => {
                  setValue("subclassification", newValue?.id);
                }}
                isOptionEqualToValue={(option, value) =>
                  option.name === value.name
                }
                getOptionLabel={(option) => option.name}
                options={subClassifications}
                loading={loading}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!!errors.subclassification}
                    variant="outlined"
                    helperText={
                      !!errors.subclassification && "This field is required."
                    }
                    fullWidth
                    label={
                      <>
                        Subclassification{" "}
                        <span className="small"> (optional)</span>
                      </>
                    }
                    InputProps={{
                      ...register("subclassification"),
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </div>
          </div>
          <div className="card my-3">
            <div className="card-body">
              <div className="row">
                <div className="col-4">
                  <label className="form-label">
                    Pack Size<span className="small"> (optional)</span>
                  </label>
                  <input
                    className="form-control"
                    {...register("pack_size", { required: false })}
                  />
                </div>
                <div className="col-4">
                  <label className="form-label">
                    Pack Type<span className="small"> (optional)</span>
                  </label>
                  <input
                    className="form-control"
                    {...register("pack_type", { required: false })}
                  />
                </div>
                <div className="col-4">
                  <label className="form-label">
                    Size<span className="small"> (optional)</span>
                  </label>
                  <input
                    className="form-control"
                    {...register("size", { required: false })}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="card my-3">
            <div className="card-body">
              <FormControlLabel
                control={
                  <Controller
                    name={"create_label_automatically"}
                    control={control}
                    render={({ field: props }) => (
                      <Checkbox
                        {...props}
                        checked={!!props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                }
                label={"Create & assign label automatically"}
              />
            </div>
          </div>
          <div className="card my-3">
            <div className="card-body">
              <FormControlLabel
                control={
                  <Controller
                    name={"kafka_sendable"}
                    control={control}
                    render={({ field: props }) => (
                      <Checkbox
                        {...props}
                        checked={!!props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                }
                label={
                  <>
                    <label>
                      Send to Kafka <br />
                      <span className="small small-bottom">
                        {" "}
                        Make the product available for MarketX services.
                      </span>
                    </label>
                  </>
                }
              />
            </div>
          </div>
          <div className="card my-3">
            <div className="card-body">
              <FormControlLabel
                control={
                  <Controller
                    name={"kafka_excluded_from_search"}
                    control={control}
                    render={({ field: props }) => (
                      <Checkbox
                        {...props}
                        checked={!!props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                }
                label={
                  <>
                    <label>
                      Excluded from search <br />
                      <span className="small small-bottom">
                        Do not show the product in search results in the MarketX
                        app.
                      </span>
                    </label>
                  </>
                }
              />
            </div>
          </div>
          <div className="bottom-bar">
            <SaveButton />
          </div>
        </form>
      </div>
      <div className="product-photos col-6">
        <FileUpload
          photo_ids={[]}
          onImageAdded={(id: number) =>
            setValue("photo_ids", [...photo_ids, id] as any)
          }
        />
      </div>
    </div>
  );
};

export default AddProduct;
