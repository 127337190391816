import React from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import MainMenu from './main-menu';
import useAuthentication from 'context/authenticationContext';

interface Props {
  children?: React.ReactNode;
}

export default function CommonLayout({ children }: Props) {
  const { state } = useAuthentication();
  return (
    <Box>
      <CssBaseline />
      <MainMenu />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        {children}
      </Box>
    </Box>
  );
}
