import { Drawer } from "@mui/material";
import CommonLayout from "components/layouts/common-layout";
import { SuppliersTable } from "components/suppliers/suppliersTable";
import { SuppliersProvider } from "context/suppliersContext";
import { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import AddSupplier from "./addSupplier";
import EditSupplier from "./editSupplier";
import ViewSupplier from "./viewSupplier";
import { FormMode } from "models/util";

interface SuppliersProps {
  mode: FormMode;
}

const SuppliersPage = (props: SuppliersProps) => {
  const [drawerTitle, setDrawerTitle] = useState<string>("Supplier");
  let { editId, previewId } = useParams();
  const [supplier, setSupplier] = useState<any>({});
  const [mode, setMode] = useState<FormMode>(props.mode);
  const navigate = useNavigate();

  useEffect(() => {
    if (editId) {
      setSupplier(null);
      setMode(FormMode.Edit);
      getSupplierById(editId).then((res: any) => {
        setSupplier(res.data.data);
      });
    }
  }, [editId]);

  useEffect(() => {
    if (previewId) {
      setMode(FormMode.View);
      getSupplierById(previewId).then((res: any) => {
        setSupplier(res.data.data);
      });
    }
  }, [previewId]);

  useEffect(() => {
    if (mode === FormMode.Create) {
      setDrawerTitle("New Supplier");
    } else if (mode === FormMode.Edit) {
      setDrawerTitle("Edit Supplier");
    } else {
      setDrawerTitle("Supplier");
    }
  }, [mode]);

  const getSupplierById = (id: string) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/suppliers/${id}`);
  };

  const onExit = () => {
    setMode(FormMode.None);
    navigate("/suppliers", { replace: true });
  };

  return (
    <CommonLayout>
      <h2 className="float-start">Suppliers</h2>
      <button
        onClick={() => {
          navigate("/suppliers/new", { replace: true });
          setMode(FormMode.Create);
        }}
        className="btn btn-primary btn-block fw-bolder float-end"
      >
        <AddIcon />
        New Supplier
      </button>
      <SuppliersProvider>
        <div className="clearfix"></div>
        <SuppliersTable />
        <Drawer
          PaperProps={{ style: { width: "600px" } }}
          anchor={"right"}
          open={mode !== FormMode.None}
          onClose={onExit}
        >
          <section className="sidebar-entity p-4">
            <h2 className="float-start">
              <ArrowBackIcon onClick={onExit} className="primary-col pointer" />
              <span className="px-2">{drawerTitle}</span>
            </h2>
            <div className="clearfix" />
            {mode === FormMode.View && <ViewSupplier supplier={supplier} />}
            {mode === FormMode.Create && <AddSupplier onExit={onExit} />}
            {mode === FormMode.Edit && supplier && (
              <EditSupplier onExit={onExit} supplier={supplier} />
            )}
          </section>
        </Drawer>
      </SuppliersProvider>
    </CommonLayout>
  );
};

export default SuppliersPage;
