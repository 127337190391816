import {
  Box,
  Drawer,
  FormControl,
  FormControlLabel,
  Modal,
  Radio,
  RadioGroup,
  Typography,
  ThemeProvider,
  Checkbox,
} from "@mui/material";
import { createTheme } from "@mui/material/styles";
import axios from "axios";
import CommonLayout from "components/layouts/common-layout";
import { ProductCatalogsTable } from "components/productCatalogs/productCatalogsTable";
import { ProductCatalogViewTable } from "components/productCatalogs/productCatalogViewTable";
import { ProductCatalogProvider } from "context/productCatalogContext";
import { ProductCatalogProductsProvider } from "context/productCatalogProductsContext";
import { FormMode } from "models/util";
import { useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SettingsIcon from "@mui/icons-material/Settings";
import { BrandCategoriesProvider } from "context/brandCategoriesContext";
import { CategoriesProvider } from "context/categoriesContext";
import { BrandsProvider } from "context/brandsContext";
import { SuppliersProvider } from "context/suppliersContext";
import { ClassificationsProvider } from "context/classificationsContext";
import { SubClassificationsProvider } from "context/subClassificationContext";
import { BrandFamiliesProvider } from "context/brandsFamiliesContext";
import EditProductInstance from "./editProduct";
import AddProductCatalog from "./addProductCatalog";
import EditIcon from "@mui/icons-material/Edit";
import EditProductCatalog from "./editProductCatalog";
import { DropZone } from "components/shared/dropZone";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import DescriptionIcon from "@mui/icons-material/Description";
import ViewProductInstance from "./viewProduct";
import { BootstrapButton } from "components/shared/bootstrapButton";
import { modalStyle } from "components/shared/modalStyles";
import { featureFlags } from "configs/featureFlags";
import { useExportRequests } from "hooks/useExportRequests";
import { PermissionGate } from "shared/functions/permissionGate";

interface ProductCatalogProps {
  mode: FormMode;
}

const ProductCatalogsPage = (props: ProductCatalogProps) => {
  const [drawerTitle, setDrawerTitle] = useState<string>("Product Catalogs");
  let {
    editId,
    previewId,
    deleteId,
    editProductId,
    editProductInstanceId,
    viewProductId,
    catalogId,
  } = useParams();
  const [productCatalog, setProductCatalog] = useState<any>({});
  const [productInstance, setProductInstance] = useState<any>(null);
  const [mode, setMode] = useState<FormMode>(props.mode);
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isModalExportOpen, setIsModalExportOpen] = useState<boolean>(false);
  const [requestExport] = useExportRequests();
  const [file, setFile] = useState<Blob>();
  const [importStrategy, setImportStrategy] = useState<string>(
    "empty_cells_set_attributes_to_master_attributes",
  );
  const [exportStrategy, setExportStrategy] =
    useState<string>("catalog_values");
  const [updateOnlyNewValues, setUpdateOnlyNewValues] = useState<boolean>(true);
  const [isUploading, setIsUploading] = useState<boolean>(false);

  useEffect(() => {
    if (editProductId && previewId) {
      setProductCatalog(null);
      setProductInstance(null);
      setMode(FormMode.Edit);
      getProductCatalogById(previewId).then((res: any) => {
        setProductCatalog(res.data.data);
        getProductInstanceById(editProductId).then((res: any) => {
          setProductInstance(res.data?.data);
        });
      });
    }
  }, [editProductId, previewId]);

  useEffect(() => {
    if (viewProductId && previewId) {
      setProductCatalog(null);
      setMode(FormMode.View);
      getProductCatalogById(previewId).then((res: any) => {
        setProductCatalog(res.data.data);
        getProductInstanceById(viewProductId).then((res: any) => {
          setProductInstance(res.data.data);
        });
      });
    }
  }, [viewProductId, previewId]);

  useEffect(() => {
    if (catalogId) {
      setProductCatalog(null);
      setMode(FormMode.Edit);
      getProductCatalogById(catalogId).then((res: any) => {
        setProductCatalog(res.data.data);
      });
    }
  }, [catalogId]);

  useEffect(() => {
    if (previewId) {
      setProductCatalog(null);
      getProductCatalogById(previewId).then((res: any) => {
        setProductCatalog(res.data.data);
      });
    }
  }, [previewId]);

  useEffect(() => {
    if (mode === FormMode.Create) {
      setDrawerTitle("Add Customer Product List");
    } else if (mode === FormMode.View) {
      if (catalogId) {
        setDrawerTitle("View Customer Product List");
      } else {
        setDrawerTitle("View Product Instance");
      }
    } else if (mode === FormMode.Edit) {
      if (catalogId) {
        setDrawerTitle("Edit Customer Product List");
      } else {
        setDrawerTitle("Edit Product Instance");
      }
    } else {
      setDrawerTitle("Product Catalog");
    }
  }, [mode]);

  const getProductCatalogById = (id: string) => {
    return axios.get(`${process.env.REACT_APP_API_URL}/product_catalogs/${id}`);
  };
  const getProductInstanceById = (productId: any) => {
    return axios.get(
      `${process.env.REACT_APP_API_URL}/product_catalogs/${previewId}/products/${productId}`,
    );
  };
  const onExit = () => {
    setFile(undefined);
    setMode(FormMode.None);
    setProductCatalog(undefined);
    setProductInstance(undefined);
    navigate("/customer-product-lists/products", { replace: true });
  };

  const onInstanceEditExit = () => {
    setMode(FormMode.None);
    setProductInstance(undefined);
    navigate(`/customer-product-lists/products/${previewId}`, {
      replace: true,
    });
  };

  const onExitDrawer = () => {
    setProductInstance(undefined);
    if (previewId) {
      setMode(FormMode.None);
      navigate(`/customer-product-lists/products/${previewId}`, {
        replace: true,
      });
    } else onExit();
  };
  const handleOpen = () => setIsModalOpen(true);
  const handleClose = () => {
    setFile(undefined);
    setIsModalOpen(false);
  };

  const handleExportModalClose = () => {
    setIsModalExportOpen(false);
  };

  const handleExportBtn = () => {
    setIsModalExportOpen(true);
  };

  const handleExport = () => {
    setIsModalExportOpen(false);
    let columns = [
      "id",
      "name",
      "ean",
      "upc",
      "supplier",
      "brand_family",
      "brand",
      "classification",
      "subclassification",
      "pack_size",
      "pack_type",
      "size",
      "region",
      "added_at",
      "custom_attributes",
    ];
    if (exportStrategy === "catalog_values") {
      columns = [
        "id",
        "external_id",
        "name",
        "ean",
        "upc",
        "supplier",
        "brand_family",
        "brand",
        "classification",
        "subclassification",
        "pack_size",
        "pack_type",
        "size",
        "region",
        "added_at",
        "custom_attributes",
      ];
    }
    requestExport("product_catalog", columns, [], {
      catalog_id: previewId,
      strategy: exportStrategy,
    });
  };

  const handleUpload = () => {
    uploadFile(file);
  };

  const onFileAdded = (file: any) => {
    setFile(file);
  };

  const uploadFile = (file: any) => {
    setIsUploading(true);
    let formData = new FormData();
    formData.append("file", file);
    axios
      .post(`${process.env.REACT_APP_API_URL}/uploads`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        setImport(res.data.data);
      });
  };

  const setImport = (data: any) => {
    let strategies = [importStrategy];
    if (updateOnlyNewValues) {
      strategies.push("update_only_new_values");
    } else {
      strategies.push("update_all_values");
    }

    axios
      .post(`${process.env.REACT_APP_API_URL}/imports`, {
        file_id: data.id,
        import_type: "product_catalogs",
        import_strategies: strategies,
        context: {
          catalog_id: previewId,
        },
      })
      .then((res) => {
        setIsUploading(false);
        navigate("/imports", { replace: true });
      });
  };

  const theme = createTheme({
    typography: {
      caption: {
        fontSize: "0.7rem",
      },
    },
  });

  const CustomLabel = ({
    label,
    subtitle,
  }: {
    label: string;
    subtitle: string;
  }) => {
    return (
      <div>
        <ThemeProvider theme={theme}>
          <Typography variant="body1">{label}</Typography>
          <Typography variant="caption" color="textSecondary">
            {subtitle}
          </Typography>
        </ThemeProvider>
      </div>
    );
  };

  return (
    <CommonLayout>
      <Modal
        open={isModalExportOpen}
        onClose={handleExportModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <h2 className="float-start normal-header">
            {previewId && (
              <ClearOutlinedIcon
                onClick={handleExportModalClose}
                className="primary-col pointer"
                style={{ marginTop: "-4px" }}
              />
            )}
            <span style={{ marginLeft: "10px" }}>Export products to CSV</span>
          </h2>
          <div className="clearfix"></div>
          <div className="card my-3">
            <div className="card-body">
              <label className="form-label">
                <strong>
                  Some of the products have both Custom and Master List values.
                  Which ones you would like to export?
                </strong>
              </label>
              <FormControl>
                <RadioGroup
                  defaultValue="catalog_values"
                  name="radio-buttons-group"
                  onChange={(e) => setExportStrategy(e.target.value)}
                >
                  <FormControlLabel
                    value="catalog_values"
                    control={<Radio />}
                    label={
                      <CustomLabel label="Current catalog values" subtitle="" />
                    }
                  />
                  <FormControlLabel
                    value="product_values"
                    control={<Radio />}
                    style={{ marginTop: "15px" }}
                    label={
                      <CustomLabel label="Master List values" subtitle="" />
                    }
                  />
                </RadioGroup>
              </FormControl>
            </div>
          </div>
          <BootstrapButton variant="contained" onClick={handleExport}>
            <label>Export</label>
          </BootstrapButton>
        </Box>
      </Modal>

      <Modal
        open={isModalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <h2 className="float-start normal-header">
            {previewId && (
              <ClearOutlinedIcon
                onClick={handleClose}
                className="primary-col pointer"
                style={{ marginTop: "-4px" }}
              />
            )}
            <span style={{ marginLeft: "10px" }}>
              Add or Edit Products via CSV
            </span>
          </h2>
          <div className="clearfix"></div>
          <DropZone onFileAdded={onFileAdded} />
          <div className="card my-3">
            <div className="card-body">
              <label className="form-label">
                <strong>How to handle empty fields in your file?</strong>
              </label>
              <FormControl>
                <RadioGroup
                  defaultValue="empty_cells_set_attributes_to_master_attributes"
                  name="radio-buttons-group"
                  onChange={(e) => setImportStrategy(e.target.value)}
                >
                  <FormControlLabel
                    value="empty_cells_set_attributes_to_master_attributes"
                    control={<Radio />}
                    label={
                      <CustomLabel
                        label="Treat empty fields as master list values"
                        subtitle="The master list values will be used for any empty fields found in the imported file."
                      />
                    }
                  />
                  <FormControlLabel
                    value="empty_cells_override_master_attributes"
                    control={<Radio />}
                    style={{ marginTop: "15px" }}
                    label={
                      <CustomLabel
                        label="Treat empty fields as custom values"
                        subtitle="Empty fields will be treated as intentional blanks that would overwrite master list values."
                      />
                    }
                  />
                </RadioGroup>
              </FormControl>
            </div>
          </div>
          <div className="card my-3">
            <div className="card-body">
              <FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={updateOnlyNewValues}
                      onChange={(e) => setUpdateOnlyNewValues(e.target.checked)}
                    />
                  }
                  label={
                    <CustomLabel
                      label="Update only new values"
                      subtitle="Check this option to import only new values from the CSV file. Existing values matching those in the table will be preserved. Leave unchecked to overwrite all values as custom."
                    />
                  }
                />
              </FormControl>
            </div>
          </div>
          <div className="card my-3">
            <div className="card-body">
              <DescriptionIcon
                className="primary-col float-left"
                style={{ fontSize: 30 }}
              />
              <p className="ts-7 m-0 float-left middle-par">
                Example of adding products to Customer Product List
              </p>
              <a
                type="button"
                href={`${process.env.REACT_APP_API_BASE_URL}/imports/add_products_to_catalog.csv`}
                className="btn btn-sm btn-secondary float-right"
              >
                Download
              </a>
            </div>
          </div>
          <div className="card my-3">
            <div className="card-body">
              <DescriptionIcon
                className="primary-col float-left"
                style={{ fontSize: 30 }}
              />
              <p className="ts-7 m-0 float-left middle-par">
                Example of editing products within Customer Product List
              </p>
              <a
                type="button"
                href={`${process.env.REACT_APP_API_BASE_URL}/imports/edit_products_within_catalog.csv`}
                className="btn btn-sm btn-secondary float-right"
              >
                Download
              </a>
            </div>
          </div>
          {!isUploading && !!file && (
            <>
              <BootstrapButton variant="contained" onClick={handleUpload}>
                <FileUploadIcon fontSize="small" />
                <label>Upload</label>
              </BootstrapButton>
            </>
          )}
          {isUploading && (
            <>
              <BootstrapButton
                disabled
                variant="contained"
                onClick={handleUpload}
              >
                <label>In progress...</label>
              </BootstrapButton>
            </>
          )}
        </Box>
      </Modal>
      <h2 className="float-start">
        {previewId && (
          <ArrowBackIcon onClick={onExit} className="primary-col pointer" />
        )}

        {productCatalog && previewId ? (
          <>
            {productCatalog?.name}
            <PermissionGate permission="write" resource="product_catalogs">
              <EditIcon
                fontSize="small"
                className="primary-col pointer"
                onClick={() => {
                  navigate(
                    "/customer-product-lists/product/edit/" + previewId,
                    { replace: true },
                  );
                  setMode(FormMode.Create);
                }}
              />
            </PermissionGate>
          </>
        ) : (
          <>Customer Product Lists</>
        )}
      </h2>
      {productCatalog && previewId ? (
        <>
          <div className="float-end">
            {/* Existing Custom Attributes button */}
            <NavLink
              to={`/customer-product-lists/custom-attributes/${previewId}`}
              replace={true}
              className="btn btn-secondary btn-block fw-bolder no-underline me-1"
              onClick={() => {
                setMode(FormMode.None);
              }}
            >
              <SettingsIcon />
              Custom Attributes
            </NavLink>

            {/* New Model Subscriptions button */}
            <NavLink
              to={`/customer-product-lists/model-subscriptions/${previewId}`}
              replace={true}
              className="btn btn-secondary btn-block fw-bolder no-underline me-1"
              onClick={() => {
                setMode(FormMode.None);
              }}
            >
              <SettingsIcon />
              Model Subscriptions
            </NavLink>

            {/* Add/Edit Products button */}
            <PermissionGate permission="write" resource="product_catalogs">
              <button
                onClick={handleOpen}
                className="btn btn-primary btn-block fw-bolder me-1"
              >
                <AddIcon />
                Add or Edit Products via CSV
              </button>
            </PermissionGate>

            {featureFlags.exports && (
              <button
                onClick={() => {
                  handleExportBtn();
                }}
                className="btn btn-primary btn-block fw-bolder"
              >
                Export
              </button>
            )}
          </div>
        </>
      ) : (
        <>
          <PermissionGate permission="write" resource="product_catalogs">
            <button
              onClick={() => {
                navigate("/customer-product-lists/products/new", {
                  replace: true,
                });
                setMode(FormMode.Create);
              }}
              className="btn btn-primary btn-block fw-bolder float-end floating-button-right"
            >
              <AddIcon />
              Add Customer Product List
            </button>
          </PermissionGate>
        </>
      )}
      <ProductCatalogProvider>
        <ProductCatalogProductsProvider>
          <BrandCategoriesProvider>
            <CategoriesProvider>
              <BrandsProvider>
                <SuppliersProvider>
                  <ClassificationsProvider>
                    <SubClassificationsProvider>
                      <BrandFamiliesProvider>
                        <div className="clearfix"></div>
                        {previewId ? (
                          <ProductCatalogViewTable />
                        ) : (
                          <ProductCatalogsTable />
                        )}
                        <Drawer
                          PaperProps={{
                            style: {
                              width:
                                mode === FormMode.View ? "1200px" : "700px",
                            },
                          }}
                          anchor={"right"}
                          open={
                            mode == FormMode.Create ||
                            mode == FormMode.Edit ||
                            mode == FormMode.View
                          }
                          onClose={onExitDrawer}
                        >
                          <section className="sidebar-entity p-4">
                            <h2 className="float-start">
                              <ArrowBackIcon
                                onClick={onExitDrawer}
                                className="primary-col pointer"
                              />
                              <span className="px-2">{drawerTitle}</span>
                            </h2>
                            <div className="clearfix" />
                            {mode === FormMode.Edit &&
                              !catalogId &&
                              productInstance && (
                                <EditProductInstance
                                  onExit={onInstanceEditExit}
                                  product={productInstance}
                                />
                              )}
                            {mode === FormMode.Edit &&
                              catalogId &&
                              productCatalog && (
                                <EditProductCatalog
                                  onExit={onExit}
                                  product={productCatalog}
                                />
                              )}
                            {mode === FormMode.View && productInstance && (
                              <ViewProductInstance
                                onExit={onExit}
                                product={productInstance}
                              />
                            )}
                            {mode === FormMode.Create && (
                              <AddProductCatalog onExit={onExit} />
                            )}
                            <div className="clearfix" />
                          </section>
                        </Drawer>
                      </BrandFamiliesProvider>
                    </SubClassificationsProvider>
                  </ClassificationsProvider>
                </SuppliersProvider>
              </BrandsProvider>
            </CategoriesProvider>
          </BrandCategoriesProvider>
        </ProductCatalogProductsProvider>
      </ProductCatalogProvider>
    </CommonLayout>
  );
};

export default ProductCatalogsPage;
