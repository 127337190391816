import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import useProducts from "context/productContext";
import { Autocomplete, Checkbox, FormControlLabel, TextField, Chip } from "@mui/material";
import useBrandFamilies from "context/brandsFamiliesContext";
import useBrands from "context/brandsContext";
import useCategories from "context/categoriesContext";
import useSubClassifications from "context/subClassificationContext";
import useSuppliers from "context/suppliersContext";
import useClassifications from "context/classificationsContext";
import useModelLists from "context/modelListsContext";
import { SaveButton } from "components/shared/saveButton";
import LockIcon from '@mui/icons-material/Lock';
import FileUpload from "./fileUpload";
import CustomAttributeForm from "../productCatalogs/customAttributes";
import useLabels from "context/labelsContext";

// Interface definitions
interface ProductAttribute {
  id: string | null;
  value: string | string[] | null;
  name: string;
  data_type: string;
  attribute_definition_id: string;
}

interface GroupedAttribute {
  group_name: string;
  data: ProductAttribute[];
}

const EditProduct = (props: any) => {
  const [groupedAttributes, setGroupedAttributes] = useState<GroupedAttribute[]>(props.product.grouped_attributes || []);
  const [selectedModelLists, setSelectedModelLists] = useState<any[]>(props.product.model_lists || []);
  const { editLabel } = useLabels();
  const { editProduct, getProducts, editAttributeValue, createAttributeValue } = useProducts();
  const { brandFamilies, getBrandFamilies } = useBrandFamilies();
  const { getBrands, brands } = useBrands();
  const { categories, getCategories } = useCategories();
  const { getSuppliers, suppliers } = useSuppliers();
  const { subClassifications, getSubClassifications } = useSubClassifications();
  const { getClassifications, classifications } = useClassifications();
  const { modelLists, searchModelLists } = useModelLists();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    watch,
    setValue
  } = useForm({
    defaultValues: {
      id: props.product.id,
      name: props.product.name,
      ean: props.product.ean,
      upc: props.product.upc,
      brand: props.product.brand?.id,
      category: props.product.category?.id,
      brand_family: props.product.brand_family?.id,
      classification: props.product.classification?.id,
      subclassification: props.product.subclassification?.id,
      supplier: props.product.supplier?.id,
      size: props.product.size,
      pack_size: props.product.pack_size,
      pack_type: props.product.pack_type,
      region: props.product.region,
      kafka_sendable: props.product.kafka_sendable,
      kafka_excluded_from_search: props.product.kafka_excluded_from_search,
      photo_ids: props.product.photo_ids || [],
      grouped_attributes: props.product.grouped_attributes || [],
      model_list_ids: props.product.model_lists?.map((ml: any) => ml.id) || [],
    }
  });

  const fullName = watch("name");
  const region = watch("region");
  const pack_type = watch("pack_type");
  const pack_size = watch("pack_size");
  const size = watch("size");
  const photo_ids = watch("photo_ids");

  const fullNameDisplayed = () => {
    let name = fullName;
    if (pack_type) {
      name += " " + pack_type;
    }
    if (pack_size) {
      name += " " + pack_size;
    }
    if (size) {
      name += " " + size;
    }
    return name;
  };

  useEffect(() => {
    getBrands({ page: 1, items_per_page: 200 });
    getCategories({ page: 1, items_per_page: 200 });
    getBrandFamilies({ page: 1, items_per_page: 200 });
    getSuppliers({ page: 1, items_per_page: 200 });
    getSubClassifications({ page: 1, items_per_page: 200 });
    getClassifications({ page: 1, items_per_page: 200 });
    searchModelLists({ filters: [] });
  }, []);

  const getBrandForName = (searchTerm: string) => {
    getBrands({ page: 1, items_per_page: 20, filters: [{ field: "name", value: searchTerm }] });
  };

  const getSupplierForName = (searchTerm: string) => {
    getSuppliers({ page: 1, items_per_page: 20, filters: [{ field: "name", value: searchTerm }] });
  };

  const getCategoryForName = (searchTerm: string) => {
    getCategories({ page: 1, items_per_page: 20, filters: [{ field: "name", value: searchTerm }] });
  };

  const getBrandFamilyForName = (searchTerm: string) => {
    getBrandFamilies({ page: 1, items_per_page: 20, filters: [{ field: "name", value: searchTerm }] });
  };

  const getClassificationForName = (searchTerm: string) => {
    getClassifications({ page: 1, items_per_page: 20, filters: [{ field: "name", value: searchTerm }] });
  };

  const getSubclassificationForName = (searchTerm: string) => {
    getSubClassifications({ page: 1, items_per_page: 20, filters: [{ field: "name", value: searchTerm }] });
  };

  const getModelListsForName = (searchTerm: string) => {
    searchModelLists({ filters: [{ field: "name", value: searchTerm }] });
  };

  const handleGroupedAttributesChange = (updatedGroupedAttributes: GroupedAttribute[]) => {
    setGroupedAttributes(updatedGroupedAttributes);
  };

  const transformGroupedAttributesPayload = (groupedAttrs: GroupedAttribute[]) => {
    const transformedAttributes: { attribute_definition_id: string; name: string; value: string | string[] | null }[] = [];

    groupedAttrs.forEach(group => {
      group.data.forEach(attr => {
        transformedAttributes.push({
          attribute_definition_id: attr.attribute_definition_id,
          name: attr.name,
          value: attr.value
        });
      });
    });

    return transformedAttributes;
  };

  const handleGroupedAttributesSubmit = (groupedAttrs: GroupedAttribute[]) => {
    const productId = props.product.id;
    groupedAttrs.forEach((group) => {
      group.data.forEach((attr: ProductAttribute) => {
        const currentAttributeValue = props.product.grouped_attributes
          ?.find((g: GroupedAttribute) => g.group_name === group.group_name)?.data
          ?.find((a: ProductAttribute) => a.name === attr.name)?.value;

        if (attr.id) {
          handleGroupedAttributeUpdate(productId, currentAttributeValue, attr);
        } else {
          handleGroupedAttributeCreate(productId, currentAttributeValue, attr);
        }
      });
    });
  };

  const handleGroupedAttributeUpdate = (productId: string, currentValue: string | string[], attr: ProductAttribute) => {
    if (JSON.stringify(attr.value) !== JSON.stringify(currentValue)) {
      editAttributeValue(productId, attr);
    }
  };

  const handleGroupedAttributeCreate = (productId: string, currentValue: string | string[], attr: ProductAttribute) => {
    if (JSON.stringify(attr.value) !== JSON.stringify(currentValue)) {
      createAttributeValue(productId, attr);
    }
  };

  return (props.product && brandFamilies && brands && categories && suppliers && subClassifications && classifications ?
    <div className="product-split row">
      <div className="product-form col-6">
        <form
          className={errors.name ? "was-validated" : "needs-validation"}
          onSubmit={handleSubmit((data) => {
            handleGroupedAttributesSubmit(groupedAttributes);
            const transformedGroupedAttributes = transformGroupedAttributesPayload(groupedAttributes);
            const payload = {
              ...data,
              grouped_attributes: transformedGroupedAttributes,
              model_list_ids: data.model_list_ids
            };

            if (props.label && props.label.id) {
              editLabel(payload, props.label.id).then((error: any) => {
                if (!error) {
                  props.onExit();
                }
              });
            }
            editProduct(payload).then((error: any) => {
              if (!error) {
                props.onExit();
              }
            });
          })}
          noValidate
        >
          <div className="card">
            <div className="card-body">
              <label className="form-label">Name</label>
              <input
                className="form-control"
                {...register("name", { required: true })}
                required
              />
              {errors.name && <div className="invalid-feedback">
                <p className="warning-icon">This field is required.</p>
              </div>}
              <div className="my-3">
                <label className="form-label">Full Name &nbsp; <LockIcon fontSize="inherit" /></label>
                <input className="form-control" disabled value={fullNameDisplayed()} />
              </div>
              <div className="row">
                <div className="col-6">
                  <label className="form-label">EAN<span className="small"> (optional)</span></label>
                  <input className="form-control" {...register("ean", { required: false })} />
                </div>
                <div className="col-6">
                  <label className="form-label">UPC<span className="small"> (optional)</span></label>
                  <input className="form-control" {...register("upc", { required: false })} required />
                </div>
              </div>
              <div className="my-3">
                <label className="form-label">Region</label>
                <input className="form-control" {...register("region", { required: false })} />
              </div>
            </div>
          </div>
          <div className="card my-3">
            <div className="my-3 px-3">
              <Autocomplete
                onInputChange={(event, newInputValue) => {
                  getCategoryForName(newInputValue);
                }}
                onChange={(event, newValue) => {
                  setValue("category", newValue?.id);
                }}
                isOptionEqualToValue={(option, value) => option.name === value.name}
                getOptionLabel={(option) => option.name}
                options={categories}
                defaultValue={props.product.category}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!!errors.category}
                    variant="outlined"
                    helperText={!!errors.category && "This field is required."}
                    fullWidth
                    label="Category"
                    InputProps={{
                      ...register('category', {
                        required: "This field is required."
                      }),
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </div>
          </div>
          <div className="card my-3">
            <div className="my-3 px-3">
              <Autocomplete
                onInputChange={(event, newInputValue) => {
                  getSupplierForName(newInputValue);
                }}
                onChange={(event, newValue) => {
                  setValue("supplier", newValue?.id);
                }}
                isOptionEqualToValue={(option, value) => option.name === value.name}
                getOptionLabel={(option) => option.name}
                options={suppliers}
                defaultValue={props.product.supplier}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!!errors.supplier}
                    variant="outlined"
                    helperText={!!errors.supplier && "This field is required."}
                    fullWidth
                    label={<>Supplier <span className="small"> (optional)</span></>}
                    InputProps={{
                      ...register('supplier'),
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </div>
            <div className="my-3 px-3">
              <Autocomplete
                onInputChange={(event, newInputValue) => {
                  getBrandFamilyForName(newInputValue);
                }}
                onChange={(event, newValue) => {
                  setValue("brand_family", newValue?.id);
                }}
                isOptionEqualToValue={(option, value) => option.name === value.name}
                getOptionLabel={(option) => option.name}
                options={brandFamilies}
                defaultValue={props.product.brand_family}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!!errors.brand_family}
                    variant="outlined"
                    helperText={!!errors.brand_family && "This field is required."}
                    fullWidth
                    label={<>Brand Family <span className="small"> (optional)</span></>}
                    InputProps={{
                      ...register('brand_family'),
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </div>
            <div className="my-3 px-3">
              <Autocomplete
                onInputChange={(event, newInputValue) => {
                  getBrandForName(newInputValue);
                }}
                onChange={(event, newValue) => {
                  setValue("brand", newValue?.id);
                }}
                isOptionEqualToValue={(option, value) => option.name === value.name}
                getOptionLabel={(option) => option.name}
                options={brands}
                defaultValue={props.product.brand}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!!errors.brand}
                    variant="outlined"
                    helperText={!!errors.brand && "This field is required."}
                    fullWidth
                    label="Brand"
                    InputProps={{
                      ...register('brand', {
                        required: "This field is required."
                      }),
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </div>
          </div>
          <div className="card">
            <div className="my-3 px-3">
              <Autocomplete
                onInputChange={(event, newInputValue) => {
                  getClassificationForName(newInputValue);
                }}
                onChange={(event, newValue) => {
                  setValue("classification", newValue?.id);
                }}
                isOptionEqualToValue={(option, value) => option.name === value.name}
                getOptionLabel={(option) => option.name}
                options={classifications}
                defaultValue={props.product.classification}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!!errors.classification}
                    variant="outlined"
                    helperText={!!errors.classification && "This field is required."}
                    fullWidth
                    label={<>Classification <span className="small"> (optional)</span></>}
                    InputProps={{
                      ...register('classification'),
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </div>
            <div className="my-3 px-3">
              <Autocomplete
                onInputChange={(event, newInputValue) => {
                  getSubclassificationForName(newInputValue);
                }}
                onChange={(event, newValue) => {
                  setValue("subclassification", newValue?.id);
                }}
                isOptionEqualToValue={(option, value) => option.name === value.name}
                getOptionLabel={(option) => option.name}
                options={subClassifications}
                defaultValue={props.product.subclassification}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!!errors.subclassification}
                    variant="outlined"
                    helperText={!!errors.subclassification && "This field is required."}
                    fullWidth
                    label={<>Subclassification <span className="small"> (optional)</span></>}
                    InputProps={{
                      ...register('subclassification'),
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </div>
          </div>
          <div className="card my-3">
            <div className="card-body">
              <div className="row">
                <div className="col-4">
                  <label className="form-label">Pack Size<span className="small"> (optional)</span></label>
                  <input className="form-control" {...register("pack_size", { required: false })} />
                </div>
                <div className="col-4">
                  <label className="form-label">Pack Type<span className="small"> (optional)</span></label>
                  <input className="form-control" {...register("pack_type", { required: false })} />
                </div>
                <div className="col-4">
                  <label className="form-label">Size<span className="small"> (optional)</span></label>
                  <input className="form-control" {...register("size", { required: false })} />
                </div>
              </div>
            </div>
          </div>
          <div className="card my-3">
            <div className="card-body">
              <label className="form-label">Model Lists</label>
              <Autocomplete
                multiple
                options={modelLists}
                getOptionLabel={(option) => option.name}
                value={selectedModelLists}
                onChange={(event, newValue) => {
                  setSelectedModelLists(newValue);
                  setValue("model_list_ids", newValue.map((ml) => ml.id));
                }}
                onInputChange={(event, newInputValue) => {
                  getModelListsForName(newInputValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Select Model Lists"
                    placeholder="Search model lists"
                  />
                )}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      label={option.name}
                      {...getTagProps({ index })}
                      key={option.id}
                    />
                  ))
                }
              />
            </div>
          </div>
          <div className="card-body my-3">
            {groupedAttributes.length > 0 && (
              <React.Fragment>
                <label className="form-label">Additional Properties</label>
                <CustomAttributeForm initialData={groupedAttributes} onChange={handleGroupedAttributesChange} />
              </React.Fragment>
            )}
          </div>
          <div className="card my-3">
            <div className="card-body">
              <FormControlLabel
                control={
                  <Controller
                    name={"kafka_sendable"}
                    control={control}
                    render={({ field: props }) => (
                      <Checkbox
                        {...props}
                        checked={!!props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                }
                label={
                  <>
                    <label>Send to Kafka <br /><span className="small small-bottom"> Make the product available for MarketX services.</span></label>
                  </>
                }
              />
            </div>
            <div className="card-body">
              <FormControlLabel
                control={
                  <Controller
                    name={"kafka_excluded_from_search"}
                    control={control}
                    render={({ field: props }) => (
                      <Checkbox
                        {...props}
                        checked={!!props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                }
                label={
                  <>
                    <label>Excluded from search <br /><span className="small small-bottom">Do not show the product in search results in the MarketX app.</span></label>
                  </>
                }
              />
            </div>
          </div>
          <div className="bottom-bar">
            <SaveButton />
          </div>
        </form>
      </div>
      <div className="product-photos col-6">
        <FileUpload
          photo_ids={props?.product?.photos}
          onImageDeleted={() => getProducts({ page: 1, take: 20 })}
          onImageAdded={(id: number) => setValue("photo_ids", [...photo_ids, id] as any)}
        />
      </div>
    </div>
    : null
  );
};

export default EditProduct;
