import { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

const Profile = () => {
  const { user, isAuthenticated, getAccessTokenSilently, logout } = useAuth0();

  useEffect(() => {
    getAccessTokenSilently().then(res => {
      localStorage.setItem("auth_token", res);
    })
  }, [user])

  const logoutHandler = () => {
    localStorage.removeItem("auth_token");
    localStorage.removeItem("user_permissions");
    logout({ logoutParams: { returnTo: window.location.origin } });
  }

  return (<>
    {
      isAuthenticated && (
        <ul className="nav">
          <li className="nav-item dropdown">
            <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              Profile
            </a>
            <div className="dropdown-menu dropdown-menu-end">
              <div className="dropdown-item">
                <strong>{user?.name}</strong>
                <p>{user?.email}</p>
              </div>
              <hr className="dropdown-divider" />
              <a className="dropdown-item" href="#" onClick={logoutHandler}>Logout</a>
            </div>
          </li>
        </ul>
      )
    }
    <p></p>
  </>
  );
};

export default Profile;
