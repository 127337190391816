import { useState, useEffect } from "react";
import {
  Grid,
  GridColumn,
  GridDataStateChangeEvent,
  GridFilterCellProps,
  GridFilterChangeEvent,
  GridSortChangeEvent,
} from "@progress/kendo-react-grid";
import "@progress/kendo-theme-default/dist/all.css";
import { gridSettings } from "configs/gridSettings";
import useImports from "context/importsContext";
import { FullPageLoader } from "components/shared/fullPageLoader";
import { initialDataState } from "configs/initialDataState";
import { useSearchParams } from "react-router-dom";
import Toast from "components/toaster/toastr";
import ErrorToast from "components/toaster/errorToastr";
import { GuidCell } from "components/grid/GuidCell";
import {
  CompositeFilterDescriptor,
  SortDescriptor,
} from "@progress/kendo-data-query";
import { RangeFilterCell } from "components/grid/DateRangeFilterCell";
import { Chip } from "@mui/material";
import { LinearProgressWithLabel } from "components/shared/linearProgress";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { DropdownFilterCell } from "components/shared/dropdownFilterCell";
import { useSearchPagination } from "components/hooks/useSearchPagination";

const initialSort: Array<SortDescriptor> = [
  { field: "created_at", dir: "desc" },
];

const reverseTypeMapping = {
  product_catalogs: "Customer Product Lists",
  predefined_class_labels: "Predefined Class Labels",
  predefined_class_products: "Predefined Class Products",
  labels_tags: "Labels Tags",
  products: "Products",
  model_list_products: "Model List Products",
  customer_references: "Customer References",
  remove_labels_from_predefined_classes: "Remove Labels from PCs",
} as const;

type ApiValueKeys = keyof typeof reverseTypeMapping;

const displayValue = (value: string) =>
  reverseTypeMapping[value as ApiValueKeys] || value;
const filterableTypes = (Object.keys(reverseTypeMapping) as ApiValueKeys[]).map(
  (apiValue) => reverseTypeMapping[apiValue],
);
const filterableStatus = ["pending", "processed", "processing"];
const TypeFilterCell: any = (props: GridFilterCellProps) => (
  <DropdownFilterCell {...props} data={filterableTypes} defaultItem={"-"} />
);
const StatusFilterCell: any = (props: GridFilterCellProps) => (
  <DropdownFilterCell {...props} data={filterableStatus} defaultItem={"-"} />
);

const TypeCell = (props: any) => {
  return <td>{displayValue(props.dataItem[props.field])}</td>;
};

export const ImportsTable = () => {
  const [dataState, setDataState] = useState(initialDataState);
  const { imports, meta, getImports, isSuccess, message, error } = useImports();
  const [filter, setFilter] = useState<CompositeFilterDescriptor | any>(
    undefined,
  );
  const [sort, setSort] = useState(initialSort);
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchPageNumber, searchPageSize] = useSearchPagination();

  useEffect(() => {
    const d = { ...dataState };
    d.take = searchPageSize;
    d.skip = (searchPageNumber - 1) * searchPageSize;
    d.isInitial = false;
    setDataState(d);
  }, []);

  useEffect(() => {
    if (dataState.isInitial) return;
    const page = dataState.skip / dataState.take + 1;
    const take = String(dataState.take);
    setSearchParams(
      `?${new URLSearchParams({ pageNumber: String(page), pageSize: take })}`,
    );
    getImports({
      page: page,
      take: dataState.take,
      filters: filter?.filters,
      sort: sort,
    });
  }, [dataState]);

  const filterChange = (event: GridFilterChangeEvent) => {
    setFilter(event.filter);
  };

  useEffect(() => {
    if (filter === undefined) return;
    const getData = setTimeout(() => {
      const page = dataState.skip / dataState.take + 1;
      getImports({
        page: page,
        take: dataState.take,
        filters: filter?.filters,
        sort: sort,
      });
    }, 500);

    return () => clearTimeout(getData);
  }, [filter]);

  const sortChanged = (sort: SortDescriptor[]) => {
    const page = dataState.skip / dataState.take + 1;
    getImports({
      page: page,
      take: dataState.take,
      filters: filter?.filters,
      sort: sort,
    });
  };

  return (
    <div>
      {isSuccess && <Toast message={message} />}
      {error && <ErrorToast message={error} />}

      {imports && meta ? (
        <Grid
          {...gridSettings}
          data={imports}
          filter={filter}
          className="k-grid-container"
          total={meta.total_count}
          pageSize={dataState.take}
          onFilterChange={filterChange}
          onDataStateChange={(e: GridDataStateChangeEvent) => {
            setDataState(e.dataState as any);
          }}
          skip={meta.page_size * meta.current_page - meta.page_size}
          sort={sort}
          onSortChange={(e: GridSortChangeEvent) => {
            setSort(e.sort);
            sortChanged(e.sort);
          }}
        >
          <GridColumn
            field="id"
            title="UUID"
            sortable={false}
            filterable={false}
            cell={GuidCell}
            width="110"
          />
          <GridColumn
            field="import_type"
            width="200"
            title="Type"
            filterCell={TypeFilterCell}
            cell={TypeCell}
          />
          <GridColumn
            field="parent_id"
            width="200"
            title="Schedule type"
            cell={(o) => (
              <td>{o.dataItem.parent_id ? "Automatic Sync" : ""}</td>
            )}
          />
          <GridColumn
            field="user"
            title="User"
            width="250"
            cell={(o) => <td>{o.dataItem.user.email}</td>}
          />
          <GridColumn
            field="created_at"
            width="240"
            filterCell={RangeFilterCell}
            title="Created at"
          />
          <GridColumn
            field="state"
            title="State"
            width="170"
            filterCell={StatusFilterCell}
            cell={(o) => (
              <td>
                <Chip label={o.dataItem.state} />
              </td>
            )}
          />
          <GridColumn
            sortable={false}
            field="progress"
            title="Progress"
            filterable={false}
            width="100"
            cell={(o) => (
              <td>
                <LinearProgressWithLabel value={o.dataItem.progress} />
              </td>
            )}
          />
          <GridColumn
            sortable={false}
            field="results"
            title="Results"
            filterable={false}
            cell={(o) => (
              <td>
                {!o.dataItem.error_count && (
                  <>
                    <span className="text-success">
                      <CheckCircleIcon fontSize="small" />{" "}
                    </span>{" "}
                    No errors{" "}
                  </>
                )}
                {!!o.dataItem.error_count && (
                  <>
                    <span className="text-danger">
                      <CheckCircleIcon fontSize="small" />{" "}
                    </span>{" "}
                    {o.dataItem.error_count} errors
                  </>
                )}
              </td>
            )}
          />
          <GridColumn
            sortable={false}
            field="file_url"
            title="Input"
            filterable={false}
            cell={(o) => (
              <td>
                <a href={o.dataItem.file_url}>
                  {o.dataItem.file_url.split("/").pop()}
                </a>
              </td>
            )}
          />
          <GridColumn
            sortable={false}
            field="errors_file_url"
            filterable={false}
            title="Errors"
            cell={(o) => (
              <td>
                {!!o.dataItem.file_errors.length && (
                  <>
                    {" "}
                    <span className="text-danger">
                      {o.dataItem.file_errors}
                    </span>
                  </>
                )}
                {!!o.dataItem.errors_file_url && (
                  <>
                    <p>
                      <a href={o.dataItem.errors_file_url}>
                        {o.dataItem.errors_file_url.split("/").pop()}
                      </a>
                    </p>
                  </>
                )}
              </td>
            )}
          />
          <GridColumn
            sortable={false}
            field="created_records_file_url"
            filterable={false}
            title="Output"
            cell={(o) => (
              <td>
                {o.dataItem.created_records_file_url && (
                  <>
                    <p>
                      <a href={o.dataItem.created_records_file_url}>
                        {o.dataItem.created_records_file_url.split("/").pop()}
                      </a>
                    </p>
                  </>
                )}
              </td>
            )}
          />
        </Grid>
      ) : (
        <FullPageLoader />
      )}
    </div>
  );
};
