import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { NavLink } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import FileUpload from './fileUpload';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { PermissionGate } from 'shared/functions/permissionGate';

const ViewProduct = (props: any) => {
  const { product } = props;

  return (
    <>
      <div className="product-split row">
        <div className="product-form col-6">
          <div className="card">
            <div className="card-body">
              <label className="form-label">Id</label>
              <p className="mb-0">{product?.id || "-"} </p>
              <p></p>
              <label className="form-label">External Reference GUID</label>
              <p className="mb-0">{product?.papi_guid || "-"} </p>
              <p></p>
              <label className="form-label">Name</label>
              <p className="mb-0">{product?.name || "-"}</p>
              <p></p>
              <label className="form-label">Full Name</label>
              <p className="mb-3">{product?.full_name || "-"}</p>
              <div className="row">
                <div className="col-6">
                  <label className="form-label">EAN</label>
                  <p className="mb-0">{product?.ean || "-"}</p>
                </div>
                <div className="col-6">
                  <label className="form-label">UPC</label>
                  <p className="mb-0">{product?.upc || "-"}</p>
                </div>
              </div>
              <div className="py-2">
                <label className="form-label">Region</label>
                <p className="mb-0">{product?.region || "-"}</p>
              </div>
            </div>
          </div>
          <div className="card my-3">
            <div className="card-body">
              <label className="form-label">Category</label>
              <p className="mb-0">{product?.category?.name || "-"}</p>
            </div>
          </div>
          <div className="card my-3">
            <div className="card-body">
              <label className="form-label">Supplier</label>
              <p className="mb-0">{product?.supplier?.name || "-"}</p>
            </div>
            <div className="card-body">
              <label className="form-label">Brand Family</label>
              <p className="mb-0">{product?.brand_family?.name || "-"}</p>
            </div>
            <div className="card-body">
              <label className="form-label">Brand</label>
              <p className="mb-0">{product?.brand?.name || "-"}</p>
            </div>
          </div>
          <div className="card my-3">
            <div className="card-body">
              <label className="form-label">Classification</label>
              <p className="mb-0">{product?.classification?.name || "-"}</p>
            </div>
            <div className="card-body">
              <label className="form-label">Subclassification</label>
              <p className="mb-0">{product?.subclassification?.name || "-"}</p>
            </div>
          </div>
          <div className="card my-3">
            <div className="card-body">
              <div className="row">
                <div className="col-6">
                  <label className="form-label">Pack Size</label>
                  <p className="mb-0">{product?.pack_size || "-"}</p>
                </div>
                <div className="col-6">
                  <label className="form-label">Pack Type</label>
                  <p className="mb-0">{product?.pack_type || "-"}</p>
                </div>
                <div className="col-6">
                  <label className="form-label">Size</label>
                  <p className="mb-0">{product?.size || "-"}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="card my-3">
            <div className="card-body">
              <div className="row py-1">
                <div className="col-4">
                  <label className="form-label">Height (mm)</label>
                  <p className="mb-0">{product?.dimensions?.height_mm || "-"}</p>
                </div>
                <div className="col-4">
                  <label className="form-label">Width (mm)</label>
                  <p className="mb-0">{product?.dimensions?.width_mm || "-"}</p>
                </div>
                <div className="col-4">
                  <label className="form-label">Depth (mm)</label>
                  <p className="mb-0">{product?.dimensions?.depth_mm || "-"}</p>
                </div>
              </div>
              <div className="row py-1">
                <div className="col-4">
                  <label className="form-label">Height (inch)</label>
                  <p className="mb-0">{product?.dimensions?.height_inch || "-"}</p>
                </div>
                <div className="col-4">
                  <label className="form-label">Width (inch)</label>
                  <p className="mb-0">{product?.dimensions?.width_inch || "-"}</p>
                </div>
                <div className="col-4">
                  <label className="form-label">Depth (inch)</label>
                  <p className="mb-0">{product?.dimensions?.depth_inch || "-"}</p>
                </div>
              </div>
            </div>
          </div>
          <label className="form-label">Model Lists</label>
          <div className="card my-3">
            <div className="card-body">
              {product?.model_lists?.map((m: any) => (
                <p className="mb-0">{m.name || "-"}</p>
              ))}
            </div>
          </div>
          <label className="form-label">Additional Properties</label>
          <div className="card my-3">
            <div className="card-body">
              {product?.grouped_attributes?.map((group: any) => (
                <div>
                  <h5 className="group-title">{group.group_name}</h5>
                  <div key={group.group_name} className="grouped-attributes mb-3">
                    {group.data.map((attribute: any) => (
                      <div key={attribute.attribute_definition_id} className="attribute-item">
                        <div className="attribute-content">
                          <label className="attribute-name">{attribute.name}</label>
                          {Array.isArray(attribute.value) ? (
                            <Stack direction="row" spacing={{ xs: 1, sm: 2 }} flexWrap="wrap" className="chips-container">
                              {attribute.value.map((val: string, index: number) => (
                                <Chip key={index} label={val} className="chip-element" />
                              ))}
                            </Stack>
                          ) : (
                            <p className="attribute-value">{attribute.value || "-"}</p>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="card my-3">
            <div className="card-body">
              {product?.kafka_sendable ? (<CheckIcon fontSize='small' className='true-icon icon-left' />) : (<ClearIcon fontSize='small' className='false-icon icon-left' />)}
              <label>Send to Kafka <br /><span className="small small-bottom"> Make the product available for MarketX services.</span></label>
            </div>
          </div>
          <div className="card my-3">
            <div className="card-body">
              {product?.kafka_excluded_from_search ? (<CheckIcon fontSize='small' className='true-icon icon-left' />) : (<ClearIcon fontSize='small' className='false-icon icon-left' />)}
              <label>Excluded from search <br /><span className="small small-bottom">Do not show the product in search results in the MarketX app.</span></label>
            </div>
          </div>
          <div className="card my-3">
            <div className="card-body">
              <label className="form-label">Labels</label>
              <ul>
                {product?.labels?.map((l: any) => (
                  <li key={l.id}> <NavLink to={`/labels/${l.id}`} replace={true}>{l.name}</NavLink><br />{l.id}</li>
                ))}
              </ul>
            </div>
          </div>

          <div className="card my-3">
            <div className="card-body">
              <table className="table">
                <thead>
                  <tr>
                    <th>Product Descriptions</th>
                  </tr>
                </thead>
                <tbody>
                  {product?.descriptions?.length > 0 ? (
                    product.descriptions.map((description: any) => (
                      <tr key={description.content}>
                        <td>{description.content}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td>No descriptions available</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>

          <PermissionGate permission="write" resource="products">
            <div className="bottom-bar">
              <NavLink to={`/products/edit/${product?.id}`} replace={true}>
                <button type="button" className="save-ui btn btn-primary btn-block fw-bolder float-end"><EditIcon fontSize="small" />Edit</button>
              </NavLink>
            </div>
          </PermissionGate>
        </div>
        <div className="product-photos col-6">
          <FileUpload readOnly={true} photo_ids={props?.product?.photos} onImageAdded={null} />
        </div>
      </div >
    </>
  );
}

export default ViewProduct;
