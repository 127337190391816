import axios from "axios";
import { ModelList } from "models/model_list";
import { createContext, useReducer, useContext, ReactNode } from "react";
import { modelListsReducer, initialState } from "reducers/modelListsReducer";
import { convertFiltersToQueryString, Meta, setFailure, setLoading, generateSortQueryParam, GetEntitiesProps } from "models/util";
import { processDateObjects } from "shared/functions/processDateObjects";

const ModelListsContext = createContext(initialState);

interface Props {
  children?: ReactNode;
}

export const ModelListsProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer(modelListsReducer, initialState);

  const getModelLists = ({ page = 1, take = 20, filters = [], sort = [] }: GetEntitiesProps) => {
    const f = convertFiltersToQueryString(filters);
    setLoading(dispatch);
    axios.get(`${process.env.REACT_APP_API_URL}/model_lists/?page=${page}&items_per_page=${take}${f}${generateSortQueryParam(sort)}`)
      .then(res => {
        setModelLists(processDateObjects(res.data.data), res.data.meta)
      },
        () => setFailure(dispatch, "Error getting Model Lists"))
  }

  const searchModelLists = ({ filters = [] }: GetEntitiesProps) => {
    const f = convertFiltersToQueryString(filters);
    setLoading(dispatch);
    axios.get(`${process.env.REACT_APP_API_URL}/model_lists/search?${f}`)
      .then(res => {
        setModelLists(processDateObjects(res.data.data), res.data.meta)
      },
        () => setFailure(dispatch, "Error getting Model Lists"))
  }

  const getModelList = (id: string) => {
    setLoading(dispatch);
    axios.get(`${process.env.REACT_APP_API_URL}/predefined_classes/${id}`)
      .then(res => {
        setModelLists(processDateObjects([res.data.data]), res.data.meta)
      },
        () => setFailure(dispatch, "Error getting Model Lists"))
  }

  const setModelLists = (modelLists: ModelList[], meta: Meta) => {
    dispatch({
      type: "success",
      results: { modelLists: modelLists, meta },
    });
  };

  const value = {
    isLoading: state.isLoading,
    error: state.error,
    modelLists: state.modelLists,
    meta: state.meta,
    setModelLists: setModelLists,
    getModelList: getModelList,
    getModelLists: getModelLists,
    searchModelLists: searchModelLists,
    setLoading,
    setFailure,
  };
  return <ModelListsContext.Provider value={value}>{children}</ModelListsContext.Provider>;
};

const useModelLists = () => {
  const context = useContext(ModelListsContext);

  if (context === undefined) {
    throw new Error("useModelLists must be used within ModelListsContext");
  }

  return context;
};

export default useModelLists;
