import CommonLayout from "components/layouts/common-layout";
import { ModelListsTable } from "components/modelLists/modelListsTable";
import { ModelListsProvider } from "context/modelListsContext";
import { FormMode } from "models/util";
import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ModelListProductsProvider } from "context/modelListProductsContext";

interface ModelListsProps {
  mode: FormMode;
}

const ModelListsPage = (props: ModelListsProps) => {
  let { previewId } = useParams();
  const [pc, setPc] = useState<any>({});

  useEffect(() => {
    if (previewId) {
      setPc(null);
      getModelListById(previewId).then((res: any) => {
        setPc(res.data.data);
      });
    }
  }, [previewId]);

  const getModelListById = (id: string) => {
    return axios.get(
      `${process.env.REACT_APP_API_URL}/predefined_classes/${id}`,
    );
  };

  return (
    <CommonLayout>
      <h2 className="float-start">Model Lists</h2>
      <ModelListsProvider>
        <ModelListProductsProvider>
          <div className="clearfix"></div>
          <ModelListsTable />
        </ModelListProductsProvider>
      </ModelListsProvider>
    </CommonLayout>
  );
};

export default ModelListsPage;
