import React, { createContext, useContext, ReactNode, useState, useEffect } from 'react';
import axios from 'axios';
import { Permissions } from 'models/permission';
import { useAuth0 } from "@auth0/auth0-react";

const api = axios.create({
  baseURL: 'https://mydomain.com/api',
});

const PermissionContext = createContext<PermissionContextType | null>(null);

interface PermissionContextType {
  permissions: Permissions | null;
  loading: boolean;
  error: string | null;
  fetchPermissions: () => Promise<void>;
}

const CACHE_KEY = 'user_permissions';
const CACHE_EXPIRATION = 1000 * 60 * 60; // 1 hour in milliseconds

const getCachedPermissions = (): Permissions | null => {
  try {
    const cachedData = localStorage.getItem(CACHE_KEY);
    if (cachedData) {
      const { permissions, timestamp } = JSON.parse(cachedData);
      if (Date.now() - timestamp < CACHE_EXPIRATION) {
        return permissions;
      }
    }
  } catch (error) {
    console.error('Error reading from cache:', error);
  }
  return null;
};

const setCachedPermissions = (permissions: Permissions) => {
  try {
    const dataToCache = {
      permissions,
      timestamp: Date.now(),
    };
    localStorage.setItem(CACHE_KEY, JSON.stringify(dataToCache));
  } catch (error) {
    console.error('Error writing to cache:', error);
  }
};

export const PermissionProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [permissions, setPermissions] = useState<Permissions | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { getAccessTokenSilently, isAuthenticated, isLoading: isAuthLoading } = useAuth0();

  const fetchPermissions = async () => {
    try {
      setLoading(true);
      const cachedPermissions = getCachedPermissions();
      if (cachedPermissions) {
        setPermissions(cachedPermissions);
        setLoading(false);
        return;
      }

      const token = await getAccessTokenSilently();
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/users/info`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      const fetchedPermissions = response.data.data.available_resources;
      setPermissions(fetchedPermissions);
      setCachedPermissions(fetchedPermissions);
      setError(null);
    } catch (error) {
      console.error('Error fetching permissions:', error);
      setError('Failed to load permissions. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isAuthenticated && !isAuthLoading) {
      fetchPermissions();
    }
  }, [isAuthenticated, isAuthLoading]);

  return (
    <PermissionContext.Provider value={{ permissions, loading, error, fetchPermissions }}>
      {children}
    </PermissionContext.Provider>
  );
};

const usePermissions = (): PermissionContextType => {
  const context = useContext(PermissionContext);
  if (!context) {
    throw new Error('usePermissions must be used within a PermissionProvider');
  }
  return context;
};

export default usePermissions;
