import { useState, useEffect, SetStateAction } from 'react';
import ImageCropModal from './imageCropModal';
import useProducts from 'context/productContext';
import { Product } from '../../models/product';
import axios from 'axios';
import NiceProgress from 'components/niceProgress';
import ImageGallery from './imageGallery';

import { Box, Modal, Typography, IconButton } from '@mui/material';
import { defaultCenteredModalStyle } from "components/shared/modalStyles";
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';

interface ProductDetailsProps {
  row: any;
  alreadyExists: boolean;
  alreadyUploaded: boolean;
  notMatched: boolean;
  onNotMatched: () => void;
  onUploaded: () => void;
  onAleadyExists: () => void;
}

export function ProductDetails({ row, alreadyExists, alreadyUploaded, notMatched, onAleadyExists, onNotMatched, onUploaded }: ProductDetailsProps) {
  const [details, setDetails] = useState<Product | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [modalImage, setModalImage] = useState<{ url: string, key: string } | null>(null);
  const [imagesToPersist, setImagesToPersist] = useState<Record<string, Blob>>({});
  const [croppedImagesToPersist, setCroppedImagesToPersist] = useState<Record<string, Blob>>({});
  const [imagesPreuploadedForCropping, setImagesPreuploadedForCropping] = useState<string[]>([]);
  const [showCroppedImages, setShowCroppedImages] = useState<boolean>(false);

  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const [selectedPreviewImg, setSelectedPreviewImg] = useState('');

  const upc = row['UPC'];

  const { products, meta, getProducts, addPhotosToProduct, isLoading } = useProducts();

  useEffect(() => {
    setImagesToPersist({})
    setImagesPreuploadedForCropping([])
    const initialImages: Record<string, Blob> = {};
    const fetchPackshotImages = async () => {
      for (let i = 1; i <= 6; i++) {
        const packshotKey = `Packshot ${i}`;
        const packshotUrl = row[packshotKey];
        if (packshotUrl) {
          try {
            const response = await fetch(packshotUrl);
            const blob = await response.blob();
            initialImages[packshotKey] = blob;
          } catch (error) {
            console.error(`Error fetching ${packshotKey}:`, error);
          }
        }
      }
      setImagesToPersist(initialImages);
    };

    console.log({ upc: upc })
    if (typeof upc === 'number' && upc !== 0) { 
      const filters = [{ field: "upc", value: upc.toString() }]
      console.log(filters)
      getProducts({ page: 1, filters: filters })
      fetchPackshotImages()
    }
  }, [upc]);

  useEffect(() => {
    console.log("Starting to upload files for cropping.");
    const uploadFilesToTemp = async () => {
      Object.values(imagesToPersist).forEach((photo) => {
        try {
          let formData = new FormData();
          formData.append("photo", photo);
          console.log(formData);
          axios.post(`${process.env.REACT_APP_API_URL}/photos`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }).then((res) => {
            const key = res.data.data.key;
            const fastlyUrl = `https://brandbank-stg.global.ssl.fastly.net/${key}?width=200&height=200&fit=bounds&quality=80&trim-color=auto`;
            console.log(`Fastly URL: ${fastlyUrl}`);
            setImagesPreuploadedForCropping(prev => [...prev, fastlyUrl]);
            console.log("Current ImagesPreuploadedForCropping: "+imagesPreuploadedForCropping);

            if (fastlyUrl) {
              try {
                fetch(fastlyUrl)
                  .then(response => response.blob())
                  .then(blob => {
                    console.log("RESPONSE FROM fetching fastly: ", blob);
                    setCroppedImagesToPersist({ fastlyUrl: blob }); // Wrap blob in an object
                  })
                  .catch(error => console.error(`Error fetching:`, error));
              } catch (error) {
                console.error(`Error fetching:`, error);
              }
            }
          })

        } catch (error) {
          console.error(`Error uploading photo:`, error);
        }
      })
    };

    if (Object.keys(imagesToPersist).length > 0) {
      uploadFilesToTemp();
    }
  }, [imagesToPersist]);

  useEffect(() => {
    console.log('Trying to reconsider products collection')
    if (!isLoading) {
      if (products && products.length > 0) {
        const firstProduct = products[0];
        console.log(firstProduct)
        setDetails(firstProduct)
      } else {
        console.log('Products response empty.')
        setDetails(null)
        onNotMatched() // TODO: Causing issues.
      }
    } else {
      console.log("Products seems that are loading.")
    }
  }, [products]);

  const handleImageClick = (imageUrl: string, key: string) => {
    setModalImage({ url: imageUrl, key });
  };

  const handleCroppedImage = (croppedImageBlob: Blob) => {
    if (modalImage) {
      setImagesToPersist(prev => ({
        ...prev,
        [modalImage.key]: croppedImageBlob
      }));
      
      const newImageUrl = URL.createObjectURL(croppedImageBlob);
      setDetails(prevDetails => {
        // if (prevDetails) {
        //   return {
        //     ...prevDetails,
        //     photos: prevDetails.photos?.map((photo: any) => 
        //       photo.key === modalImage.key ? { ...photo, original_url: newImageUrl } : photo
        //     )
        //   };
        // }
        return prevDetails;
      });
      setModalImage(null);
    }
  };

  const handleCroppedPackshotsUpload = () => {
    console.log("Click handleCroppedPackshotsUpload detected, initiating file upload process.")
    if(details && details.id) {
      console.log(`Initiating upload for product ID: ${details.id}`);
      console.log("Images to persist:", imagesToPersist);
      uploadFiles(true)
    }
  };

  const handleOriginalPackshotsUpload = () => {
    console.log("Click handleOriginalPackshotsUpload detected, initiating file upload process.")
    if(details && details.id) {
      console.log(`Initiating upload for product ID: ${details.id}`);
      console.log("Images to persist:", imagesToPersist);
      uploadFiles(false)
    }
  };

  const uploadFile = (photo: any, productId: String) => {
    let formData = new FormData();
    formData.append("photo", photo);
    console.log(formData);
    axios.post(`${process.env.REACT_APP_API_URL}/photos`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }).then((res) => {
      console.log("Upload successful. Photo ID:", res.data.data.id);
      // const key = res.data.data.key
      // const fastlyUrl = "https://brandbank-stg.global.ssl.fastly.net/${key}?width=200&height=200&fit=bounds&quality=80&trim-color=auto"

      addPhotosToProduct(productId, [res.data.data.id]).then(() => {
        console.log("Photos added to product successfully.");
        onUploaded()
        // TODO: Should we reload collections related to photos?
      });
    })
  };

  const uploadFiles = (uploadCropped: Boolean) => {
    if(details && details.id) {
      console.log("Uploading files for product ID:", details.id);
      console.log("Number of files to upload:", imagesToPersist.length);
      console.log("Photos Array:", imagesToPersist);

      if(uploadCropped) {
        console.log("Uploading cropped images.");
        Object.values(croppedImagesToPersist).forEach((photo) => {
          uploadFile(photo, details.id);
        });
      } else {
        console.log("Uploading original images.");
        Object.values(imagesToPersist).forEach((photo) => {
          uploadFile(photo, details.id);
        }); 
      }
    } else {
      console.log("Product ID is missing.");
    }

    // if (photosArray.length > 0) {
    //   Promise.all(photosArray.map((photo: any) => {
    //     let formData = new FormData();
    //     formData.append("photo", photo);
    //     return axios.post(`${process.env.REACT_APP_API_URL}/photos`, formData, {
    //       headers: {
    //         "Content-Type": "multipart/form-data",
    //       },
    //     }).then((res) => {
    //       console.log("Info: ", res);
    //       console.log("Details: ", res.data.data);
    //       return res.data.data.id;
    //     });
    //   })).then((ids) => {
    //     addPhotosToProduct(productId, ids).then(() => {
    //       console.log("Uploads completed successfully.");
    //       console.log("Response details:", ids);
    //       // setIsUploading(false);
    //       // handleModalClose();
    //       onUploaded()
    //     });
    //   });
    // }
  };

  const openProductDetails = () => {
    if(details && details.id) {
      window.open(`/products/${details.id}`, '_blank', 'toolbar=yes,scrollbars=yes,resizable=yes')
      return false
    }
  };

  const handleAlreadyExistsClick = () => {
    console.log("Already exists click detected.");
    onAleadyExists()
  };

  const openPreviewModal = (imgSrc: SetStateAction<string>) => {
    setSelectedPreviewImg(imgSrc);
    setIsPreviewModalOpen(true);
  };

  const closePreviewModal = () => {
    setSelectedPreviewImg('');
    setIsPreviewModalOpen(false);
  };

  return (
    <>
      {isLoading && <NiceProgress basePhrase="Matching in progress..."/>}
      {!isLoading && error && <p>Error: {error}</p>}
      {!isLoading && !details && <p>Oopsie woopsie! It seems like there is no match.</p>}
      {!isLoading && !error && details && (
        <>
          <h5 className="card-title mb-1">Brandbank Data</h5>
          <div className="card shadow-sm mb-3">
            <div className="card-body">
              <table className="table table-sm table-bordered">
                <tbody>
                  {[
                    { label: "Name", value: details.name },
                    { label: "UPC", value: details.upc },
                    // { label: "Supplier", value: details.supplier },
                    // { label: "Brand Family", value: details.brand_family?.name },
                    // { label: "Brand", value: details.brand?.name },
                    // { label: "Classification", value: details.classification?.name },
                    // { label: "Subclassification", value: details.subclassification?.name },
                    { label: "Pack Type", value: details.pack_type },
                    { label: "Pack Size", value: details.pack_size },
                    { label: "Size", value: details.size }
                  ].map(({ label, value }) => (
                    <tr key={label}>
                      <th className="bg-light text-secondary" style={{ width: '30%', fontSize: '0.9rem' }}>{label}</th>
                      <td className="text-muted" style={{ fontSize: '0.9rem' }}>{value ?? 'N/A'}</td>
                    </tr>
                  ))}
                </tbody>
              </table>

              {details && details.id && (
                <div className="row mt-3 justify-content-end">
                  <div className="col-auto">
                    <button className="btn btn-primary" onClick={() => openProductDetails()}>More details</button>
                  </div>
                </div>
              )}
              
              <div 
                className="row mt-3"
                style={{
                  overflowX: 'visible',
                  overflowY: 'scroll'
                }}
              >
                {Object.keys(details.photos || {}).length > 0 ? (
                  <ImageGallery 
                    photos={details.photos} 
                    handleImageClick={(originalUrl, key) => handleImageClick(originalUrl, key)} 
                  />
                ) : (
                  <div className="col-12">
                    <p className="text-muted">
                      It looks like we're missing some packshots for this product. Let's get some uploaded!
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>

          <h5 className="card-title mt-1 mb-1">Packshots to upload</h5>
          <div className="card shadow-sm mb-2">
            <div className="card-body">
              <div className="row mt-3">
              <div className="row mt-3 justify-content-end">
                <div className="col-auto">
                  <button 
                    className={`btn ${showCroppedImages ? 'btn-warning' : 'btn-primary'}`} 
                    onClick={() => setShowCroppedImages(prevState => !prevState)}
                  >
                    {showCroppedImages ? 'Show Original Images' : 'Show Cropped Images'}
                  </button>
                </div>
              </div>
              </div>
              <div className="row mt-3">
                {!showCroppedImages && Object.entries(imagesToPersist).map(([key, blob]) => {
                  return (
                    <div key={key} className="col-md-4 mb-3">
                      <img 
                        src={URL.createObjectURL(blob)}
                        alt={`${key}`}
                        className="img-fluid"
                        style={{ maxWidth: '100%', cursor: 'pointer', border: '1px solid rgba(221, 221, 221, 0.5)' }}
                        // onClick={() => handleImageClick(URL.createObjectURL(blob), key)}
                        onClick={() => openPreviewModal(URL.createObjectURL(blob))}
                      />
                    </div>
                  );
                })}
                
                {showCroppedImages && Object.entries(imagesPreuploadedForCropping).map(([key, url]) => (
                  <div key={key} className="col-md-4 mb-3">
                    <img 
                      src={url}
                      alt={key}
                      className="img-fluid"
                      style={{ maxWidth: '100%', cursor: 'pointer', border: '1px solid rgba(221, 221, 221, 0.5)' }}
                      onClick={() => handleImageClick(url, key)}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="text-end">
            <button
              className={`btn ${alreadyUploaded ? 'btn-success' : 'btn-primary'}`}
              onClick={() => handleOriginalPackshotsUpload()}
              disabled={alreadyUploaded}
            >
              {alreadyUploaded ? 'Uploaded' : 'Upload Original Packshots'}
            </button>

            <button
              className={`btn ${alreadyUploaded ? 'btn-success' : 'btn-primary'} ms-2`}
              onClick={() => handleCroppedPackshotsUpload()}
              disabled={alreadyUploaded}
            >
              {alreadyUploaded ? 'Uploaded' : 'Upload Cropped Packshots'}
            </button>
            <button
              className={`btn ${alreadyExists ? 'btn-warning' : 'btn-secondary'} ms-2`}
              onClick={() => handleAlreadyExistsClick()}
              disabled={alreadyExists}
            >
              {alreadyExists ? 'Marked as Existing' : 'Already Exists'}
            </button>
          </div>

          {/* {modalImage && (
            <ImageCropModal
              imageUrl={modalImage.url}
              onClose={() => setModalImage(null)}
              onSave={handleCroppedImage}
            />
          )} */}

          <Modal
                open={isPreviewModalOpen}
                onClose={closePreviewModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
            <Box sx={{ 
              ...defaultCenteredModalStyle, 
              maxHeight: '90vh', 
              overflowY: 'auto' 
            }}>
              <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography id="image-modal-title" variant="h6" component="h2">
                  Image Preview
                </Typography>
                <IconButton onClick={closePreviewModal} aria-label="Close Modal">
                  <ClearOutlinedIcon />
                </IconButton>
              </Box>
              <Box display="flex" justifyContent="center">
                <img src={selectedPreviewImg} alt="Preview image" style={{ maxWidth: '100%', height: 'auto' }} />
              </Box>
            </Box>
          </Modal>
        </>
      )}
    </>
  );}
